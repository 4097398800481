import React, { useState } from 'react'
import img from '../../Assets/detailspage/video.png'
import { MdArrowForwardIos } from 'react-icons/md';
import img1 from '../../Assets/detailspage/img1.png'
import img2 from '../../Assets/detailspage/img2.png'
import img3 from '../../Assets/detailspage/img3.png'
import img4 from '../../Assets/detailspage/img4.png'
import img5 from '../../Assets/detailspage/img5.png'
import img6 from '../../Assets/detailspage/img6.png'
import img8 from '../../Assets/Homeimgs/img1.svg'
import img9 from '../../Assets/Homeimgs/img2.svg'
import img10 from '../../Assets/Homeimgs/img3.svg'
import img11 from '../../Assets/Homeimgs/img4.svg'
import img12 from '../../Assets/Homeimgs/img5.svg'
import img13 from '../../Assets/detailspage/techer.jpeg'
import AppointmentModal from '../Home/AppointmentModal';
const Overview = ({ courseData }) => {
    const [openFAQ, setOpenFAQ] = useState(null);

    const toggleDropdown = (id) => {
        setOpenFAQ(openFAQ === id ? null : id);
    };
    const [open, setOpen] = useState(false);

    const handleAddOpen = () => {
        setOpen(true);
    };

    const handleModalClose = () => {
        setOpen(false);
    };

    return (
        <div className='bg-[#fff8f8] py-10'>
            <div className='px-6 md:px-16 py-10 flex flex-col md:flex-row space-x-0 md:space-x-7 space-y-5 md:space-y-0'>
                <div className='bg-white w-full md:w-[70%] px-10 space-y-4 py-4 border h-fit rounded-md'>
                    <h1 className='text-[#0044EB] text-xl font-bold tracking-wide'>Overview</h1>
                    <h2 className='text-black font-bold'>Course Description</h2>
                    <p className='text-[15.43px] font-normal'>{courseData?.overview?.description || "No description available."}</p>
                    <div>
                        <h2 className='text-black font-bold'>Key Points</h2>
                        <div className='flex flex-col md:flex-row justify-between text-gray-800 tracking-wide mt-4'>
                            <ul className='space-y-2 text-[15.43px] font-normal'>
                                {(courseData?.overview?.keyPoints1 || []).map((data, index) => (
                                    <li key={index} className='list-disc'>{data}</li>
                                ))}
                            </ul>
                            <ul className='space-y-2 text-[15.43px] font-normal'>
                                {(courseData?.overview?.keyPoints2 || []).map((data, index) => (
                                    <li key={index} className='list-disc'>{data}</li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className='w-full md:w-[30%] bg-white p-8 flex flex-col space-y-3 border h-fit rounded-md'>
                    <img src={img} alt='Course Preview' />


                    <button className='border-2 rounded-xl border-[#0044eb] py-3'><a
                        href="../../Assets/Homeimgs/Brochure.pdf"
                        download
                    >Download Brochure</a>
                    </button>



                    <button className='border-2 rounded-xl text-white bg-[#0044eb] py-3' onClick={handleAddOpen}>Enroll Now</button>
                </div>
            </div>
            {open && <AppointmentModal onClose={handleModalClose} />}
            <div className='md:px-16 px-6 flex flex-col md:flex-row space-x-0 md:space-x-7 '>
                <div className="bg-white w-full md:w-[70%] p-5 border rounded-md h-fit">
                    <h1 className="text-[#0044EB] text-xl font-bold tracking-wide mx-6">Course Content</h1>
                    {(courseData?.courseContent?.courseFaq || []).map((faq) => (
                        <div key={faq.id} className="bg-white rounded-md my-2">
                            <div
                                className="p-4 rounded-lg flex justify-between items-center cursor-pointer"
                                onClick={() => toggleDropdown(faq.id)}
                            >
                                <h2 className={`text-[17.14px] font-medium ${openFAQ === faq.id ? "text-yellow-500" : "text-black"}`}>
                                    {faq.question}
                                </h2>
                                <MdArrowForwardIos className={`fas ${openFAQ === faq.id ? "rotate-90" : "rotate-0"} transition-transform duration-300`} />
                            </div>
                            {openFAQ === faq.id && (
                                <div className="p-4 rounded-lg text-[15.43px] font-normal">
                                    <p>{faq.answer}</p>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
                <div className='w-full md:w-[30%] p-8 flex flex-col space-y-3 bg-white border h-fit rounded-md'>
                    <h1 className='text-[#0044EB] text-xl font-bold tracking-wide'>Includes</h1>
                    <p className='text-[15.43px] font-normal flex space-x-2'><img src={img1} alt='img' /><span>11 hours on-demand video</span></p>
                    <p className='text-[15.43px] font-normal flex space-x-2'><img src={img2} alt='img' /><span>69 downloadable resources</span></p>
                    <p className='text-[15.43px] font-normal flex space-x-2'><img src={img3} alt='img' /><span>Full lifetime access</span></p>
                    <p className='text-[15.43px] font-normal flex space-x-2'><img src={img4} alt='img' /><span>Access on mobile and TV</span></p>
                    <p className='text-[15.43px] font-normal flex space-x-2'><img src={img5} alt='img' /><span>Assignments</span></p>
                    <p className='text-[15.43px] font-normal flex space-x-2'><img src={img6} alt='img' /><span>Certificate of Completion</span></p>
                </div>
            </div>
            <div className='flex flex-col md:flex-row space-x-0 md:space-x-7 md:mx-16 mx-6 mt-10'>
                <div className="bg-white w-full md:w-[70%] p-5 border rounded-md h-fit">
                    <h2 className="text-[#0044EB] text-xl font-bold tracking-wide">About the Tutor</h2>
                    <div className="flex md:flex-row flex-col items-center justify-center text-center mb-4 mt-4">
                        <img src={img13} alt="Profile" className="w-16 h-16 rounded-full md:mr-4" />
                        <div>
                            <h3 className="text-lg font-bold text-gray-800">{courseData?.tutor?.name || "Instructor"}</h3>
                            <p className="text-gray-600">{courseData?.tutor?.title || "Expert Instructor"}</p>
                        </div>
                        <div className="ml-auto flex items-center mx-auto">
                            <div className="flex items-center text-yellow-500">
                                {[...Array(Math.floor(courseData?.tutor?.rating || 0))].map((_, index) => (
                                    <i key={index} className="fas fa-star"></i>
                                ))}
                                {(courseData?.tutor?.rating || 0) % 1 !== 0 && <i className="fas fa-star-half-alt"></i>}
                            </div>
                            <span className="ml-2 text-gray-600">{courseData?.tutor?.rating || 0} Instructor Rating</span>
                        </div>
                    </div>
                    <hr className="border-gray-300 mb-4" />
                    <p className="text-gray-800 font-bold mb-2">{courseData?.tutor?.experience || "Years of experience in the field."}</p>
                    <p className="text-gray-600">Skills: {(courseData?.tutor?.skills || []).join(", ")}</p>
                </div>
                <div className='w-full md:w-[30%] p-8 flex flex-col space-y-3 bg-white border h-fit rounded-md'>
                    <h1 className='text-[#0044EB] text-xl font-bold tracking-wide'>Includes</h1>
                    <p className='text-[15.43px] font-normal flex space-x-2'><img src={img8} alt='img' /><span>Enrolled: 32 students</span></p>
                    <p className='text-[15.43px] font-normal flex space-x-2'><img src={img9} alt='img' /><span>Duration: 20 hours</span></p>
                    <p className='text-[15.43px] font-normal flex space-x-2'><img src={img10} alt='img' /><span>Chapters: 15</span></p>
                    <p className='text-[15.43px] font-normal flex space-x-2'><img src={img11} alt='img' /><span>Video: 12 hours</span></p>
                    <p className='text-[15.43px] font-normal flex space-x-2'><img src={img12} alt='img' /><span>Level: Beginner</span></p>
                </div>
            </div>
            <div className='md:px-16 px-6 flex space-x-7 mt-10'>

                <div className="p-6 bg-white rounded-lg shadow-lg w-full lg:w-[69%]">
                    <h2 className="text-xl font-semibold text-blue-600 mb-4">Placement Students</h2>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6">
                        {courseData.placementStudents.map((student, index) => (
                            <div key={index} className="text-center">
                                <img
                                    src={student.img}
                                    alt={student.name}
                                    className="w-full h-48 object-cover rounded-md mb-2"
                                />
                                <h3 className="text-lg font-medium text-gray-800">{student.name}</h3>
                                <p className="text-blue-500">{student.company}</p>
                                <p className="text-red-500 font-semibold">{student.salary}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className='md:px-16 px-6 flex space-x-7 mt-10'>
                <div className="space-y-4 text-left w-full lg:w-[69%]">
                    {/* ---------------------------------------------- */}
                    <div className="bg-white rounded-md">
                        <div
                            className={` p-4 rounded-lg flex justify-between items-center cursor-pointer `}
                            onClick={() => toggleDropdown("faqA")}
                        >
                            <h2
                                className={`text-[17.14px] font-medium ${openFAQ === "faqA" ? "text-yellow-500" : "text-black"
                                    }`}
                            >
                                Who is eligible to apply for the internship ?
                            </h2>
                            <MdArrowForwardIos
                                className={`fas ${openFAQ === "faqA" ? "rotate-90" : "rotate-0"
                                    } transition-transform duration-300`}
                            />
                        </div>
                        {openFAQ === "faqA" && (
                            <div className="p-4 rounded-lg text-[15.43px] font-normal">
                                <p>
                                    To apply, candidates should typically be enrolled in or have
                                    completed one of our courses, though requirements may vary
                                    based on the internship role. We recommend checking specific
                                    eligibility criteria for each internship posting.
                                </p>
                            </div>
                        )}
                    </div>

                    {/* -------------------------------------------------- */}
                    <div className="bg-white rounded-md">
                        <div

                            className={`  p-4 rounded-lg flex justify-between items-center cursor-pointer `}
                            onClick={() => toggleDropdown("faq1")}
                        >
                            <h2
                                className={`text-[17.14px] font-medium ${openFAQ === "faq1" ? "text-yellow-500" : "text-black"
                                    }`}
                            >
                                What will I work on as an intern ?
                            </h2>
                            <MdArrowForwardIos
                                className={`fas ${openFAQ === "faq1" ? "rotate-90" : "rotate-0"
                                    } transition-transform duration-300`}
                            />
                        </div>
                        {openFAQ === "faq1" && (
                            <div className="p-4 rounded-lg text-[15.43px] font-normal">
                                <p>
                                    Interns typically work on real-world projects related to their
                                    area of study. Specific tasks depend on the department and
                                    project needs but often include research, development,
                                    testing, or content creation.
                                </p>
                            </div>
                        )}
                    </div>

                    <div className="bg-white rounded-md">
                        <div

                            className={` p-4 rounded-lg flex justify-between items-center cursor-pointer `}
                            onClick={() => toggleDropdown("faq2")}
                        >
                            <h2
                                className={` text-[17.14px] font-medium ${openFAQ === "faq2" ? "text-yellow-500" : "text-black"
                                    }`}
                            >
                                How will I be evaluated during my internship ?
                            </h2>
                            <MdArrowForwardIos
                                className={`fas ${openFAQ === "faq2" ? "rotate-90" : "rotate-0"
                                    } transition-transform duration-300`}
                            />
                        </div>
                        {openFAQ === "faq2" && (
                            <div className=" text-[15.43px] font-normal p-4 rounded-lg">
                                <p>
                                    Evaluations are conducted by your assigned mentor or
                                    supervisor based on project contributions, punctuality, skill
                                    application, and adaptability.
                                </p>
                            </div>
                        )}
                    </div>

                    <div className="bg-white rounded-md">
                        <div

                            className={`  p-4 rounded-lg flex justify-between items-center cursor-pointer `}
                            onClick={() => toggleDropdown("faq3")}
                        >
                            <h2
                                className={` text-[17.14px] font-medium ${openFAQ === "faq3" ? "text-yellow-500" : "text-black"
                                    }`}
                            >
                                What is the duration of the internship program ?
                            </h2>
                            <MdArrowForwardIos
                                className={`fas ${openFAQ === "faq3" ? "rotate-90" : "rotate-0"
                                    } transition-transform duration-300`}
                            />
                        </div>
                        {openFAQ === "faq3" && (
                            <div className="p-4 rounded-lg text-[15.43px] font-normal">
                                <p>
                                    The duration of the internship can vary based on the program
                                    and project requirements. Typically, internships last from 3
                                    to 6 months.
                                </p>
                            </div>
                        )}
                    </div>

                    {/* ----- */}
                    <div className="bg-white rounded-md">
                        <div

                            className={` p-4 rounded-lg flex justify-between items-center cursor-pointer `}
                            onClick={() => toggleDropdown("faq4")}
                        >
                            <h2
                                className={`text-[17.14px] font-medium ${openFAQ === "faq4" ? "text-yellow-500" : "text-black"
                                    }`}
                            >
                                Who is eligible to apply for the internship ?
                            </h2>
                            <MdArrowForwardIos
                                className={`fas ${openFAQ === "faq4" ? "rotate-90" : "rotate-0"
                                    } transition-transform duration-300`}
                            />
                        </div>
                        {openFAQ === "faq4" && (
                            <div className="p-4 rounded-lg text-[15.43px] font-normal">
                                <p>
                                    To apply, candidates should typically be enrolled in or have
                                    completed one of our courses, though requirements may vary
                                    based on the internship role. We recommend checking specific
                                    eligibility criteria for each internship posting.
                                </p>
                            </div>
                        )}
                    </div>

                    {/* ----- */}
                    <div className="bg-white rounded-md">
                        <div
                            className={` p-4 rounded-lg flex justify-between items-center cursor-pointer `}
                            onClick={() => toggleDropdown("faq5")}
                        >
                            <h2
                                className={`text-[17.14px] font-medium ${openFAQ === "faq5" ? "text-yellow-500" : "text-black"
                                    }`}
                            >
                                Can I convert this internship into a full-time role ?
                            </h2>
                            <MdArrowForwardIos
                                className={`fas ${openFAQ === "faq5" ? "rotate-90" : "rotate-0"
                                    } transition-transform duration-300`}
                            />
                        </div>
                        {openFAQ === "faq5" && (
                            <div className="p-4 rounded-lg text-[15.43px] font-normal">
                                <p>
                                    Yes, high-performing interns may be offered a full-time
                                    position based on available roles and departmental needs at
                                    the end of their internship.
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Overview;

