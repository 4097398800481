import React from "react";
import Banner from "../../Assets/CareersImg/Benifits_banner.png";
import logo1 from "../../Assets/CareersImg/LogoOne.png";
import logo2 from "../../Assets/CareersImg/LogoTwo.png";
import logo3 from "../../Assets/CareersImg/LogoThree.png";
import logo4 from "../../Assets/CareersImg/LogoFour.png";
import logo5 from "../../Assets/CareersImg/LogoFive.png";
import logo6 from "../../Assets/CareersImg/LogoSix.png";
const Benifits = () => {
  return (
    <body
      style={{ backgroundImage: `url(${Banner})` }}
      class="flex items-center justify-center bg-cover bg-center px-6 md:px-16 py-8 md:py-16"
    >
      <div class="text-center lg:px-4">
        <h1 class="text-[24px] lg:text-[40px] font-semibold text-gray-900 mb-4">
          Benefits that You Get from Us !
        </h1>
        <p class="text-gray-600 mb-8 lg:px-24 px-3">
        "Enjoy flexibility, creative freedom, paid leave, work-travel opportunities, yearly bonuses, and a smart salary – designed to support your success."
        </p>
        <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          <div class="bg-white px-6 py-12 rounded-lg shadow-lg">
            <div class="flex items-center justify-center mb-4">
              <img src={logo1} alt="No image" />
            </div>
            <h2 class="text-xl font-semibold text-gray-900 mb-2">Creativity</h2>
            <p class="text-gray-600">
            "Fosters innovation, encourages fresh ideas, and allows unique problem-solving approaches."
            </p>
          </div>
          <div class="bg-white px-6 py-12 rounded-lg shadow-lg">
            <div class="flex items-center justify-center mb-4">
              <img src={logo2} alt="No image" />
            </div>
            <h2 class="text-xl font-semibold text-gray-900 mb-2">
              Flexible Hours
            </h2>
            <p class="text-gray-600">
             "Work at your own pace, ensuring a balanced work-life harmony."
            </p>
          </div>
          <div class="bg-white px-6 py-12 rounded-lg shadow-lg">
            <div class="flex items-center justify-center mb-4">
              <img src={logo3} alt="No image" />
            </div>
            <h2 class="text-xl font-semibold text-gray-900 mb-2">
              Work & Travel
            </h2>
            <p class="text-gray-600">
            "Explore new places while working, blending productivity with cultural experiences."
            </p>
          </div>
          <div class="bg-white px-6 py-12 rounded-lg shadow-lg">
            <div class="flex items-center justify-center mb-4">
              <img src={logo4} alt="No image" />
            </div>
            <h2 class="text-xl font-semibold text-gray-900 mb-2">Paid Leave</h2>
            <p class="text-gray-600">
            "Time off with pay to recharge and maintain mental well-being."
            </p>
          </div>
          <div class="bg-white px-6 py-12 rounded-lg shadow-lg">
            <div class="flex items-center justify-center mb-4">
              <img src={logo5} alt="No image" />
            </div>
            <h2 class="text-xl font-semibold text-gray-900 mb-2">
              Yearly Bonuses
            </h2>
            <p class="text-gray-600">
            Annual rewards for exceptional performance, recognizing hard work and dedication."
            </p>
          </div>
          <div class="bg-white px-6 py-12 rounded-lg shadow-lg">
            <div class="flex items-center justify-center mb-4">
              <img src={logo6} alt="No image" />
            </div>
            <h2 class="text-xl font-semibold text-gray-900 mb-2">
              Smart Salary
            </h2>
            <p class="text-gray-600">
            "Competitive pay aligned with skills, experience, and market standards."
            </p>
          </div>
        </div>
      </div>
    </body>
  );
};

export default Benifits;
