import React from 'react'

import img1 from "../../Assets/AboutUsImg/image.png";
import img2 from "../../Assets/AboutUsImg/image-1.png";
import img3 from "../../Assets/AboutUsImg/image-2.png";
import img4 from "../../Assets/AboutUsImg/image-3.png";
import img5 from "../../Assets/AboutUsImg/image-4.png";
import img6 from "../../Assets/AboutUsImg/image-5.png";
import img7 from "../../Assets/AboutUsImg/image-6.png";
import img8 from "../../Assets/AboutUsImg/image-7.png";
import img9 from "../../Assets/AboutUsImg/image-8.png";
import img10 from "../../Assets/AboutUsImg/image-9.png";
import img11 from "../../Assets/AboutUsImg/image-10.png";
import img12 from "../../Assets/AboutUsImg/image-11.png";
import img13 from "../../Assets/AboutUsImg/image-12.png";
import img14 from "../../Assets/AboutUsImg/image-13.png";
import img15 from "../../Assets/AboutUsImg/image-14.png";
import img16 from "../../Assets/AboutUsImg/image-15.png";
import img17 from "../../Assets/AboutUsImg/image-16.png";
import img18 from "../../Assets/AboutUsImg/image-17.png";
import img19 from "../../Assets/AboutUsImg/image-18.png";
import img20 from "../../Assets/AboutUsImg/image-19.png";
const RecruitersPartners = () => {
  const universities = [
    { logo: img1, animation: 'fade-up' },
    { logo: img2, animation: 'fade-down' },
    { logo: img3, animation: 'fade-left' },
    { logo: img4, animation: 'fade-right' },
    { logo: img5, animation: 'zoom-in' },
    { logo: img6, animation: 'zoom-out' },
    { logo: img7, animation: 'flip-left' },
    { logo: img8, animation: 'flip-right' },
    { logo: img9, animation: 'slide-up' },
    { logo: img10, animation: 'slide-down' },
    { logo: img11, animation: 'slide-left' },
    { logo: img12, animation: 'slide-right' },
    { logo: img13, animation: 'fade-up-right' },
    { logo: img14, animation: 'fade-up-left' },
    { logo: img15, animation: 'flip-up' },
    { logo: img16, animation: 'flip-down' },
    { logo: img17, animation: 'zoom-in-up' },
    { logo: img18, animation: 'zoom-in-down' },
    { logo: img19, animation: 'fade-down-right' },
    { logo: img20, animation: 'fade-down-left' },
];
  return (
    
    <div>
      <div className="text-center py-12 over"  >
        <p className="text-[#5989C8] italic font-semibold text-lg md:text-xl">
          Trusted By
        </p>
        <h1 className="text-2xl md:text-4xl font-bold text-gray-900 mt-5">
        Our Trusted Recruiters Partners
        </h1>
      </div>

      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6 md:mx-16 mx-6 mb-10">
        {universities.map((university, index) => (
          <img
            data-aos={university.animation}
            src={university.logo}
            className="mx-auto"
            alt={`${university.name} logo`}
          />
        ))}
      </div>
    </div>
 
  )
}

export default RecruitersPartners