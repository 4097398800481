import { useState } from "react";
import { MdArrowForwardIos } from "react-icons/md";
import { MoveDown } from "lucide-react";
import { MoveRight } from "lucide-react";
import LASTIMG from "../../Assets/CareersImg/CarrersLastImg.png";
const FaqSection = () => {
  const [openFAQ, setOpenFAQ] = useState(null);

  const toggleDropdown = (id) => {
    setOpenFAQ(openFAQ === id ? null : id);
  };
  return (
    <div className="lg:px-16 mx-auto py-16 px-4 text-center bg-[#FDF2F8]">


      <div className="flex flex-col lg:flex-row justify-center items-center gap-6 lg:gap-0">
        <div className="space-y-4 text-left w-full lg:w-[50%]">
          {/* ---------------------------------------------- */}
          <div className="bg-white shadow-xl rounded-md p-3">
            <div
             
              className={` p-4 rounded-lg flex justify-between items-center cursor-pointer `}
              onClick={() => toggleDropdown("faqA")}
            >
              <h2
                className={`text-[17.14px] font-medium ${
                  openFAQ === "faqA" ? "text-yellow-500" : "text-black"
                }`}
              >
                Who is eligible to apply for the internship ?
              </h2>
              <MdArrowForwardIos
                className={`fas ${
                  openFAQ === "faqA" ? "rotate-90" : "rotate-0"
                } transition-transform duration-300`}
              />
            </div>
            {openFAQ === "faqA" && (
              <div className="p-4 rounded-lg text-[15.43px] font-normal">
                <p>
                  To apply, candidates should typically be enrolled in or have
                  completed one of our courses, though requirements may vary
                  based on the internship role. We recommend checking specific
                  eligibility criteria for each internship posting.
                </p>
              </div>
            )}
          </div>

          {/* -------------------------------------------------- */}
          <div className="bg-white shadow-xl rounded-md p-3">
            <div
             
              className={`  p-4 rounded-lg flex justify-between items-center cursor-pointer `}
              onClick={() => toggleDropdown("faq1")}
            >
              <h2
                className={`text-[17.14px] font-medium ${
                  openFAQ === "faq1" ? "text-yellow-500" : "text-black"
                }`}
              >
                What will I work on as an intern ?
              </h2>
              <MdArrowForwardIos
                className={`fas ${
                  openFAQ === "faq1" ? "rotate-90" : "rotate-0"
                } transition-transform duration-300`}
              />
            </div>
            {openFAQ === "faq1" && (
              <div className="p-4 rounded-lg text-[15.43px] font-normal">
                <p>
                  Interns typically work on real-world projects related to their
                  area of study. Specific tasks depend on the department and
                  project needs but often include research, development,
                  testing, or content creation.
                </p>
              </div>
            )}
          </div>

          <div className="bg-white shadow-xl rounded-md p-3">
            <div
            
              className={` p-4 rounded-lg flex justify-between items-center cursor-pointer `}
              onClick={() => toggleDropdown("faq2")}
            >
              <h2
                className={` text-[17.14px] font-medium ${
                  openFAQ === "faq2" ? "text-yellow-500" : "text-black"
                }`}
              >
                How will I be evaluated during my internship ?
              </h2>
              <MdArrowForwardIos
                className={`fas ${
                  openFAQ === "faq2" ? "rotate-90" : "rotate-0"
                } transition-transform duration-300`}
              />
            </div>
            {openFAQ === "faq2" && (
              <div className=" text-[15.43px] font-normal p-4 rounded-lg">
                <p>
                  Evaluations are conducted by your assigned mentor or
                  supervisor based on project contributions, punctuality, skill
                  application, and adaptability.
                </p>
              </div>
            )}
          </div>

          <div className="bg-white shadow-xl rounded-md p-3">
            <div
            
              className={`  p-4 rounded-lg flex justify-between items-center cursor-pointer `}
              onClick={() => toggleDropdown("faq3")}
            >
              <h2
                className={` text-[17.14px] font-medium ${
                  openFAQ === "faq3" ? "text-yellow-500" : "text-black"
                }`}
              >
                What is the duration of the internship program ?
              </h2>
              <MdArrowForwardIos
                className={`fas ${
                  openFAQ === "faq3" ? "rotate-90" : "rotate-0"
                } transition-transform duration-300`}
              />
            </div>
            {openFAQ === "faq3" && (
              <div className="p-4 rounded-lg text-[15.43px] font-normal">
                <p>
                  The duration of the internship can vary based on the program
                  and project requirements. Typically, internships last from 3
                  to 6 months.
                </p>
              </div>
            )}
          </div>

          {/* ----- */}
          <div className="bg-white shadow-xl rounded-md p-3">
            <div
             
              className={` p-4 rounded-lg flex justify-between items-center cursor-pointer `}
              onClick={() => toggleDropdown("faq4")}
            >
              <h2
                className={`text-[17.14px] font-medium ${
                  openFAQ === "faq4" ? "text-yellow-500" : "text-black"
                }`}
              >
                Who is eligible to apply for the internship ?
              </h2>
              <MdArrowForwardIos
                className={`fas ${
                  openFAQ === "faq4" ? "rotate-90" : "rotate-0"
                } transition-transform duration-300`}
              />
            </div>
            {openFAQ === "faq4" && (
              <div className="p-4 rounded-lg text-[15.43px] font-normal">
                <p>
                  To apply, candidates should typically be enrolled in or have
                  completed one of our courses, though requirements may vary
                  based on the internship role. We recommend checking specific
                  eligibility criteria for each internship posting.
                </p>
              </div>
            )}
          </div>

          {/* ----- */}
          <div className="bg-white shadow-xl rounded-md p-3">
            <div
              className={` p-4 rounded-lg flex justify-between items-center cursor-pointer `}
              onClick={() => toggleDropdown("faq5")}
            >
              <h2
                className={`text-[17.14px] font-medium ${
                  openFAQ === "faq5" ? "text-yellow-500" : "text-black"
                }`}
              >
                Can I convert this internship into a full-time role ?
              </h2>
              <MdArrowForwardIos
                className={`fas ${
                  openFAQ === "faq5" ? "rotate-90" : "rotate-0"
                } transition-transform duration-300`}
              />
            </div>
            {openFAQ === "faq5" && (
              <div className="p-4 rounded-lg text-[15.43px] font-normal">
                <p>
                  Yes, high-performing interns may be offered a full-time
                  position based on available roles and departmental needs at
                  the end of their internship.
                </p>
              </div>
            )}
          </div>
        </div>

        {/* Images Section */}
        <div className="w-full lg:w-[50%]">
          <h1 className="text-[24px] lg:text-[40px] font-semibold">
            Frequently Asked Questions
          </h1>
          <div className="h-fit">
            <img src={LASTIMG} alt="No Image" className="h-full" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqSection;
