import React from "react";
import Banner from "../../Components/ReusableComponents/Banner";
import SectionOne from "../../Components/Services/SectionOne";
import FooterBanner from "../../Components/ReusableComponents/FooterBanner";
import FeaturedCourses from "../../Components/Home/FeaturedCourses";
import Content_Section from "../../Components/Services/Content_Section";

const Services = () => {
  return (
    <div className="bg-[#fdf2f8]">
      <Banner page="Services" path="services" />
      <SectionOne />
      <Content_Section />
      <FooterBanner />
    </div>
  );
};

export default Services;
