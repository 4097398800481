import React from "react";
import logo from "../../Assets/logo/wbclogo.svg";
import { useNavigate } from "react-router-dom";
import img1 from '../../Assets/logo/location.svg'
import img2 from '../../Assets/logo/mesegs.svg'
import img3 from '../../Assets/logo/call.svg'
const Footer = () => {
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <footer className="py-8 px-4 bg-white text-gray-800">
        <div className="mx-auto grid grid-cols-1 md:grid-cols-4 gap-8 md:mx-16">
          <div>
            <img src={logo} alt="WBC logo" className="mb-4" />
            <p className="mt-4 text-gray-800 font-normal">
              WBC facilitates real-time, bidirectional communication between clients.
            </p>
          </div>
          <div>
            <h3 className="font-semibold text-gray-900">QUICK LINKS</h3>
            <ul className="mt-4 space-y-2">
              <li onClick={() => { navigate('/'); window.scrollTo(600, 0); }} className="text-gray-800 font-normal">Home</li>
              <li onClick={() => { navigate('/about_us'); window.scrollTo(600, 0); }} className="text-gray-800 font-normal" >About Us</li>
              <li onClick={() => { navigate('/services'); window.scrollTo(600, 0); }} className="text-gray-800 font-normal">Services</li>
              <li onClick={() => { navigate('/career'); window.scrollTo(600, 0); }} className="text-gray-800 font-normal">Career</li>
              <li onClick={() => { navigate('/contact'); window.scrollTo(600, 0); }} className="text-gray-800 font-normal">Contact Us</li>
            </ul>
          </div>
          <div>
            <h3 className="font-semibold text-gray-900">Courses</h3>
            <ul className="mt-4 space-y-2">
              <li onClick={() => { navigate('/detailsPage/1'); window.scrollTo(600, 0); }} className="text-gray-800 font-normal">Web Development in MERN</li>
              <li onClick={() => { navigate('/detailsPage/2'); window.scrollTo(600, 0); }} className="text-gray-800 font-normal">Full Stack Python</li>
              <li onClick={() => { navigate('/detailsPage/3'); window.scrollTo(600, 0); }} className="text-gray-800 font-normal">Full Stack Java</li>
              <li onClick={() => { navigate('/detailsPage/25'); window.scrollTo(600, 0); }} className="text-gray-800 font-normal">AI/ML</li>
              <li onClick={() => { navigate('/detailsPage/5'); window.scrollTo(600, 0); }} className="text-gray-800 font-normal">DSA</li>
              <li onClick={() => { navigate('/detailsPage/6'); window.scrollTo(600, 0); }} className="text-gray-800 font-normal">Design</li>
            </ul>
          </div>
          <div>
            <h3 className="font-semibold text-gray-900">News Letter</h3>
            <div className="mt-4">
              <input
                type="email"
                placeholder="Enter your email address"
                className="w-full px-4 py-2 border border-gray-300 rounded-md"
              />
            </div>
            <div className="mt-4 space-y-2">
              <div className="flex items-center text-gray-800">
                <img src={img1} alt='img' className="mr-2"/>
                <span className="font-normal">
                  Uco bank top floor, kolathia, khandagiri, bhubaneswar, 751030
                </span>
              </div>
              <div className="flex items-center text-gray-800">
              <img src={img2} alt='img' className="mr-2"/>
                <span className="font-normal">infowbc@gmail.com</span>
              </div>
              <div className="flex items-center text-gray-800">
              <img src={img3} alt='img' className="mr-2"/>
                <span className="font-normal">+91 9861934932</span>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-8 border-t border-gray-200 pt-4 flex justify-between items-center text-gray-800 text-sm md:flex-row flex-col">
          <p className="font-normal">© {currentYear} WBC. All rights reserved.</p>
          <div className="space-x-4">
            <span className="text-gray-800 font-normal">Terms &amp; Conditions</span>
            <span className="text-gray-800 font-normal">Privacy Policy</span>
          </div>
        </div>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
