import React from "react";
import location from "../../Assets/ContactImg/LocationIcon.png";
import EmailIcon from "../../Assets/ContactImg/EmailIcon.png";
import ContactIcon from "../../Assets/ContactImg/contact-book.png";

const ContactDetails = () => {
  const contactData = [
    {
      icon: ContactIcon,
      title: "Contact Phone Number",
      details: ["+91 9861934932", "+91 9776957641"],
    },
    {
      icon: EmailIcon,
      title: "Our Email Address",
      details: ["web.bocket@gmail.com", "hr.webbocket@gmail.com","classeswebbocket@gmail.com"],
    },
    {
      icon: location,
      title: "Our Location",
      details: [
        "Uco Bank top floor, Kolathia Square,",
        "Khandagiri, Bhubaneswar, 751030",
      ],
    },
  ];

  return (
    <div className="bg-[#faf2f2] p-6 md:px-12 md:py-16">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
        {contactData.map((item, index) => (
          <div
            key={index}
            className="flex flex-col items-start px-5 py-6 bg-white shadow-md rounded-lg"
          >
            <div className="mb-4">
              <img src={item.icon} alt="Icon" className="w-10 h-10" />
            </div>
            <h3 className="font-semibold text-[22px]">{item.title}</h3>
            {item.details.map((detail, idx) => (
              <p key={idx} className="text-gray-600 font-normal mt-2 text-[16px]">
                {detail}
              </p>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ContactDetails;
