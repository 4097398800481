import React from "react";
import lightening from "../../Assets/Services/lightening.png";
import downArrow from "../../Assets/Services/downArrowIcon.png";
const SectionOne = () => {
  return (
    <div className="flex flex-col items-center bg-pink-50 p-2 lg:p-16 ">
      <div className="rounded-lg py-6 px-4 md:px-10 lg:px-12 xl:px-16 text-center max-w-3xl lg:max-w-[69rem]">
        {/* Heading */}
        <div className="bg-white shadow-md rounded-lg lg:p-4 p-2  text-lg lg:text-5xl font-semibold text-gray-900 flex items-center justify-between space-x-2">
          <h1 className="flex items-center justify-center w-full">
            <span className="text-blue-600 ">Your Goals,</span>
            <span className="text-gray-800 ">Our Commitment</span>
          </h1>
          {/* <span
            role="img"
            aria-label="lightning"
            className="bg-blue-100 text-blue-600 rounded-full p-1"
          >
            ⚡
          </span> */}
          <img src={lightening} alt="No Image" className=" h-[4rem] w-[4rem]" />
        </div>

        {/* Subtitle */}
        <p className="mt-4 text-gray-600 text-sm leading-5 md:text-base lg:text-4xl font-medium">
          with Online and Ofline Courses, Internships and Live Projects.
        </p>

        {/* Description */}
        <p className="mt-4 text-[#333931] text-xs md:text-sm lg:text-base font-normal">
          At <span className="font-semibold">Web Rocket Classes</span>, we are
          committed to delivering top-quality education through expertly
          designed courses. Our instructors are industry professionals, ensuring
          that you receive up-to-date, practical knowledge. With flexible
          learning options, personalized support, and career-focused training,
          we provide an exceptional learning experience that helps you succeed.
        </p>
      </div>

      {/* Down Arrow */}
      <div className="mt-4 flex items-center justify-center">
        {/* <div className="bg-blue-600 text-white rounded-full p-2">⬇️</div> */}
        <img src={downArrow} alt="No Image" className="lg:w-[60px] w-[40px] " />
      </div>
    </div>
  );
};

export default SectionOne;
