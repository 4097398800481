import React from "react";
import Faculty1 from "../../Assets/Homeimgs/Faculty1.png";
import Faculty2 from "../../Assets/Homeimgs/Faculty2.png";
import Faculty3 from "../../Assets/Homeimgs/Faculty3.png";
import Faculty4 from "../../Assets/Homeimgs/Faculty4.png";
import { FaGithub, FaLinkedin } from "react-icons/fa";


const FeaturedInstructor = () => {

  const instructors = [
    {
      name: "Ramesh ch Mohapatra",
      title: "Web Developer",
      image: Faculty1,
      bgColor: "bg-red-500",
      aos:"zoom-in"
    },
    {
      name: "Satyaranjan Tripathy",
      title: "Java Developer",
      image: Faculty2,
      bgColor: "bg-yellow-500",
     aos:"zoom-in-up"
    },
    {
      name: "Sidharth Srichandan",
      title: "Ui/UX Guide",
      image: Faculty3,
      bgColor: "bg-purple-500",
      aos:"zoom-in-down"
    },
    {
      name: "Prasanta Kumar Mohanty",
      title: "Python Developer",
      image: Faculty4,
      bgColor: "bg-orange-500",
      aos:"zoom-in-left"
    },
  ];

  return (
    <div>
      <div className="py-12">
        <div className="text-center mb-12">
          <p className="text-[#5989C8] italic font-semibold text-lg md:text-xl">
            Faculty
          </p>
          <h1 className="text-2xl md:text-4xl font-bold text-black mt-2">
            Featured Instructor
          </h1>
          <p className="text-gray-600 mt-4 md:max-w-[50%] mx-auto tracking-wide">
            "An expert instructor with years of industry experience, dedicated to empowering students through practical knowledge, hands-on training, and a passion for lifelong learning."
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 md:mx-16 mx-6">
          {instructors.map((instructor, index) => (
            <div
              key={index}
              className="shadow-lg overflow-hidden rounded-md"
              data-aos={instructor.aos}
            >
              <img
                className="w-[100%]"
                src={instructor.image}
                alt={`Instructor ${instructor.name}`}
              />
              <div className="p-6 text-center">
                <h2 className="text-xl font-bold">{instructor.name}</h2>
                <p className="text-blue-500">{instructor.title}</p>
                <div className="flex justify-center mt-4 space-x-4">
                  <span className="text-gray-600">
                    <FaLinkedin />
                  </span>
                  <span className="text-gray-600">
                    <FaGithub />
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FeaturedInstructor;
