import React from "react";
import BannerImg from "../../Assets/ContactImg/BG_Image.png";
const Banner = ({ page, path }) => {
  return (
    <div
      style={{ backgroundImage: `url(${BannerImg})` }}
      className="relative bg-cover bg-center flex items-center justify-center h-[250px] lg:h-[365px]"
    >
      <div className="tracking-wide">
        <p className=" text-center text-[30px] lg:text-[60px] font-bold color-[#0E2A46]">
          {page}
        </p>
        <p className="color-[#0E2A46] text-center font-semibold text-[16px]">
          <span className="text-[#FC6441]">// </span>
          {path}
        </p>
      </div>
    </div>
  );
};

export default Banner;
