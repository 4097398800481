import React from "react";
import courses from './data'
import star from "../../Assets/Homeimgs/star.svg";
import FeaturedCoursesbg from "../../Assets/Homeimgs/FeaturedCoursesbackground.png";
import { useNavigate } from "react-router-dom";

function Card({ course, navigate }) {
  return (
    <div className="bg-white rounded-lg shadow-lg p-4" data-aos={course.details.aos}  onClick={() => {
      navigate(`/detailsPage/${course.id}`)
    }}>
      <img
        src={course.details.image}
        alt={course.details.name}
        className="w-[100%] h-[15rem] rounded-md object-fill"
      />
      <div className="pt-4 space-y-5" >
        <h2 className="text-lg font-semibold">{course.details.name}</h2>
        {/* <div className="flex items-center space-x-2 mt-2">
          <span className="text-red-500 text-xl font-bold">
            ₹{course.details.price}
          </span>
          <span className="line-through text-gray-500">
            ₹{course.details.originalPrice}
          </span>
        </div> */}
        <div className="text-gray-600 mt-2 flex justify-between">
          <p>{course.details.lesson} Lesson</p>
          <p>{course.details.assignment} Assignments</p>
          <p>{course.details.enrolled} Enrolled</p>
        </div>
        <div className="flex items-center justify-between">
          <div className="flex items-center ">
            <div className="flex items-center text-yellow-500">
              {[...Array(Math.floor(course.details.rating))].map((_, i) => (
                <img src={star} alt="img" />
              ))}
              {course.rating % 1 !== 0 && (
                <i className="fas fa-star-half-alt"></i>
              )}
            </div>
            <span className="text-gray-600">
              {course.rating} ({course.details.reviews})
            </span>
          </div>
          <button className="p-3 border-2	 rounded-2xl border-[#0044EB]">View Details</button>
        </div>
      </div>
    </div>
  );
}

const FeaturedCourses = () => {
  const navigate = useNavigate();
  return (
    <div
      className="py-16"
      style={{
        backgroundImage: `url(${FeaturedCoursesbg})`,
        backgroundSize: "contain",
      }}
    >
      <div className="flex md:mx-16 mx-6 justify-between md:flex-row flex-col space-y-5">
        <div>
          <p className="text-[#5989C8] italic font-semibold text-lg md:text-xl"  data-aos="fade-left">
            What’s New
          </p>
          <h1 className="text-2xl md:text-4xl font-bold text-gray-900 mt-2" data-aos="fade-down-right">
            Featured Courses
          </h1>
        </div>
        {/* <div>
          <button class="bg-[#064585] text-white font-bold py-2 px-5 rounded-md">
            View All
          </button>
        </div> */}
      </div>
      <div className="grid  md:grid-cols-2 lg:grid-cols-3 grid-cols-1 md:mx-16 mx-6 gap-10 mt-10">
        {courses.map((course, index) => (
          <Card key={index} course={course} navigate={navigate} />
        ))}
      </div>
    </div>
  );
};

export default FeaturedCourses;
