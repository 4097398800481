import React from 'react'
import img1 from '../../Assets/Homeimgs/Instructor.svg'
import img2 from '../../Assets/Homeimgs/Education.svg'

const BecomeAnInstructor = () => {
    return (
        <div>
            <div className="bg-pink-50">
                <div className="max-w-7xl mx-auto p-6">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div className="bg-[#DAE6FF] p-6 rounded-lg flex items-center"  data-aos="fade-right">
                            <div>
                                <h2 className="text-xl font-semibold text-gray-800">Become An Instructor</h2>
                                <p className="text-gray-600 mt-2">Top instructors from around the world teach millions of students on Mentoring.</p>
                                <button className="mt-4 bg-blue-600 text-white px-4 py-2 rounded">Join Us</button>
                            </div>
                            <div className="ml-auto">
                                <img src={img1} alt="Illustration" />
                            </div>
                        </div>
                        <div className="bg-[#FFE2DA] p-6 rounded-lg flex items-center" data-aos="fade-up-right">
                            <div>
                                <h2 className="text-xl font-semibold text-gray-800">Transform Access To Education</h2>
                                <p className="text-gray-600 mt-2">Create an account to receive our newsletter, course recommendations and promotions.</p>
                                <button className="mt-4 bg-blue-600 text-white px-4 py-2 rounded">Join Us</button>
                            </div>
                            <div className="ml-auto">
                                <img src={img2} alt="Illustration" />
                            </div>
                        </div>
                    </div>
                  

                </div>
            </div>
        </div>
    )
}

export default BecomeAnInstructor