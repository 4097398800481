import React from 'react'
import bgimg from '../../Assets/Homeimgs/WantToSharebg.png'
import img from '../../Assets/Homeimgs/share.svg'
import right from '../../Assets/Homeimgs/Right.svg'
const WantToShare = () => {
    return (
        <div
        className="flex flex-col md:flex-row items-center p-8 px-6 md:px-16 mt-10"
            style={{
                backgroundImage: `url(${bgimg})`,
                backgroundSize: "cover"
            }}>
                <div className="md:w-1/2" data-aos="flip-left">
                    <img
                        src={img}
                        alt="Illustration of a mentor with books and educational icons"
                    />
                </div>
                <div className="md:w-1/2 md:pl-8 mt-8 md:mt-0" >
                    <h1 className="text-2xl md:text-4xl font-semibold text-gray-900 mt-2" data-aos="flip-right">
                        Want to share your knowledge?
                    </h1>
                    <h1 className="text-2xl md:text-4xl font-bold text-[#02183F] mt-2" data-aos="flip-down">
                        Join us as a Mentor!
                    </h1>
                    <p className="text-gray-600 mt-4 font-normal" data-aos="zoom-in">
                        High-definition video is video of higher resolution and quality than standard-definition. While there is no standardized meaning for high-definition, generally any video.
                    </p>
                    <ul className="mt-6 grid grid-cols-2 gap-4"  data-aos="zoom-in-up">
                        <li className="flex items-center text-lg text-gray-800 font-normal" >
                            <img src={right} alt='img' className='mr-3' />
                            Teach Students
                        </li>
                        <li className="flex items-center text-lg text-gray-800 font-normal">
                            <img src={right} alt='img' className='mr-3' />
                            Earn Incentives
                        </li>
                        <li className="flex items-center text-lg text-gray-800 font-normal">
                            <img src={right} alt='img' className='mr-3' />
                            Professional Growth
                        </li>
                        <li className="flex items-center text-lg text-gray-800 font-normal">
                            <img src={right} alt='img' className='mr-3' />
                            Rewarding Experience
                        </li>
                    </ul>
                    <button className="mt-6 bg-blue-900 text-white py-2 px-6 rounded-full text-lg font-semibold"  data-aos="zoom-in-down">
                        Join us as a Mentor
                    </button>
                </div>
            </div>
      
    )
}

export default WantToShare