import React from "react";
import Banner from "../../Components/ReusableComponents/Banner";
import CareerEnhance from "../../Components/Career/CareerEnhance";
import JobOpennings from "../../Components/Career/JobOpennings";
import Benifits from "../../Components/Career/Benifits";
import FaqSection from "../../Components/Career/FaqSection";
import FooterBanner from "../../Components/ReusableComponents/FooterBanner";

const Career = () => {
  return (
    <div className="bg-[#FDF2F8]">
      <Banner page="Career" path="career" />
      <CareerEnhance />
      <JobOpennings />
      <Benifits />
      <FaqSection />
      <FooterBanner />
    </div>
  );
};

export default Career;
