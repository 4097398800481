import React from 'react';
import img1 from '../../Assets/detailspage/book.svg';
import img2 from '../../Assets/detailspage/time.svg';
import img3 from '../../Assets/detailspage/student.svg';

const SimpleBanner = ({ image, name, description, category, lesson, students }) => {
    return (
        <div
            className='h-[350px] md:h-[350px] flex items-center justify-center'
            style={{
                backgroundImage: `url(${image})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
            }}
        >
            <div className='text-center md:text-left md:ml-12 text-white space-y-4 md:space-y-7 p-4 md:p-0 flex flex-col items-center justify-center'>
                <button className='bg-[#0044EB] rounded-md px-3 py-1 md:px-5 md:py-2 text-xs md:text-base'>
                    {category}
                </button>
                <h1 className='text-2xl md:text-5xl font-bold tracking-wide capitalize'>
                    {name}
                </h1>
                <p className='tracking-wide max-w-[90%] text-center md:max-w-[70%] mx-auto md:mx-0 text-sm md:text-base'>
                    {description}
                </p>
                <div className='flex flex-col md:flex-row justify-around w-full md:w-[80%] mx-auto md:mx-0 space-y-3 md:space-y-0'>
                    <div className='flex items-center justify-center text-sm md:text-base'>
                        <img className='mr-2 w-4 md:w-6' src={img1} alt='lesson icon' />
                        {lesson} Lesson
                    </div>
                    <div className='flex items-center justify-center text-sm md:text-base'>
                        <img className='mr-2 w-4 md:w-6' src={img2} alt='assignment icon' />
                        Assignment
                    </div>
                    <div className='flex items-center justify-center text-sm md:text-base'>
                        <img className='mr-2 w-4 md:w-6' src={img3} alt='students icon' />
                        {students} students enrolled
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SimpleBanner;
