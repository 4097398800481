import React from 'react'
// import img from '../../Assets/AboutUsImg/AboutSectionOne.png'
import img from '../../Assets/AboutUsImg/AboutSectionOne2.png'
const StrongFoundations = () => {
    return (
        <div className="grid grid-cols-1 md:grid-cols-2 md:mx-16 mx-6 mt-10 mb-10">
            <div>
                <img
                    src={img}
                    alt="A student holding books and smiling"
                    className="mb-4"
                />
            </div>
            <div className="flex flex-col justify-center">
                <div className="text-[#5989C8] italic font-semibold text-lg md:text-xl">About us</div>
                <h1 className="text-2xl md:text-4xl font-bold text-gray-900 mt-5">
                    Building Strong Foundations for Lifelong Success
                </h1>
                <p className="text-gray-600 mt-4 tracking-wide">
                    At Web_Bocket Classes, we are passionate about empowering individuals through education. Established with the goal of providing high-quality, accessible learning opportunities, we offer a wide range of courses designed to equip students with the skills and knowledge they need to thrive in today's competitive world.
                </p>
                <ul className="text-gray-600 list-disc list-inside mt-4 tracking-wide">
                    <li>We take care of our students</li>
                    <li>Clean Atmosphere & Discipline is our moto</li>
                </ul>
            </div>
        </div>

    )
}

export default StrongFoundations