import React from 'react'
import img1 from '../../Assets/Homeimgs/semicons.png'
import people1 from '../../Assets/Homeimgs/testmonial1.jpg'
import people2 from '../../Assets/Homeimgs/testmonial2.jpg'
import people3 from '../../Assets/Homeimgs/people3.png'
import people4 from '../../Assets/Homeimgs/people4.png'
const OurTestimonials = () => {
    const testimonials = [
        {
            text: "The teacher would sit in front of us and teach through Google meet. He would type code and all the students would just copy it or do nothing just watch the class. I found this type of teaching very amature considering the amount I paid for.",
            name: "Sraddhananda Rath",
            image: people1,
            quoteColor: "gray",
            aos: "zoom-in-left"
        },
        {
            text: "The UI/UX design course exceeded my expectations. The instructor's expertise and practical assignments helped me improve my design skills. I feel more confident in my career now. Thank you!",
            name: "Pratyush Nayak",
            image: people2,
            quoteColor: "blue",
            aos: "zoom-in-right"
        },
        {
            text: "WebBocket delivers excellent project guidance tailored to our needs. Their expert team ensures success from start to finish. Highly recommended",
            name: "Jyotipriya Jena",
            image: people3,
            quoteColor: "gray",
            aos: "zoom-out"
        },
        {
            text: "The web developer training provided a solid foundation, blending theory with practical exercises, equipping me with the tools to excel in the field",
            name: "Deepak Kumar Mahanta",
            image: people4,
            quoteColor: "gray",
            aos: "zoom-out-up"
        }
    ];
    return (
        <div className='md:mx-16 mx-6 mt-10 mb-10'>
            <h2 className="text-2xl md:text-4xl font-bold text-black mt-2" data-aos="zoom-out-right">Our Testimonials</h2>
            <p className="text-gray-600 mt-4 md:max-w-[60%] tracking-wide" data-aos="zoom-out-left">
                Discover what our clients say about us! Real stories highlighting our dedication, quality service, and trust. Each testimonial showcases how we exceed expectations and build lasting, impactful relationships.
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-10">
                {testimonials.map((testimonial, index) => (
                    <TestimonialCard
                        aos={testimonial.aos}
                        key={index}
                        text={testimonial.text}
                        name={testimonial.name}
                        image={testimonial.image}
                        quoteColor={testimonial.quoteColor}
                    />
                ))}
            </div>
        </div>
    )
}
export default OurTestimonials

function TestimonialCard({ text, name, image, quoteColor ,aos}) {
    return (
        <div className="bg-white p-6 rounded-lg shadow-md" data-aos={aos}>
            <p className="text-gray-800 mb-4">{text}</p>
            <div className="flex items-center">
                <img className="mr-4 w-11 h-11 rounded-md" src={image} alt={`Portrait of ${name}`} />
                <div>
                    <p className="text-gray-900 font-bold">{name}</p>
                </div>
                <div className={`ml-auto text-${quoteColor}-500 text-2xl`}>
                    <img src={img1} alt='img' />
                </div>
            </div>
        </div>
    );
}