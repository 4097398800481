import React, { useRef, useState } from "react";
import emailjs from "emailjs-com";
import axios from "axios";
import { IoMdClose } from "react-icons/io";
import img1 from '../../Assets/logo/mern.png';
import img2 from '../../Assets/logo/java.png';
import img3 from '../../Assets/logo/student.png';
import img4 from '../../Assets/logo/field.png';
import img5 from '../../Assets/logo/marketting.png';
import img6 from '../../Assets/logo/srexecutive.png';
import img7 from '../../Assets/logo/logo.png';
import herobg from '../../Assets/Homeimgs/FeaturedCoursesbackground.png';

const JobApplicationModal = ({ job, onClose }) => {
  const modalRef = useRef();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    resume: null,
  });

  const handleBackdropClick = (e) => {
    if (modalRef.current === e.target) {
      onClose();
    }
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData({
      ...formData,
      [name]: files ? files[0] : value,
    });
  };

  let cloudname = "dnw1ttr02";
  let preset = "wjs1zyo5";
  let resumeUrl = null;

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Upload the resume to Cloudinary first
    if (formData.resume) {
      const formDataCloudinary = new FormData();
      formDataCloudinary.append("file", formData.resume);
      formDataCloudinary.append("upload_preset", preset);

      try {
        const response = await axios.post(
          `https://api.cloudinary.com/v1_1/${cloudname}/image/upload`,
          formDataCloudinary
        );
        console.log(response);
        resumeUrl = response?.data?.secure_url;
        console.log(resumeUrl);
      } catch (error) {
        console.error("Cloudinary upload failed:", error);
        return;
      }
    }

    const templateParams = {
      name: formData.name,
      email: formData.email,
      phone: formData.phone,
      job_title: job.title,
      message: `Application for ${job.title}`,
      resume_url: resumeUrl,
    };

    // Send email with the uploaded resume URL
    emailjs
      .send("service_w1jorre", "template_zkbw9ca", templateParams, "fOK-P5pDabgEiwzOW")
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          onClose();
        },
        (error) => {
          console.log("FAILED...", error);
        }
      );
  };

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
      onClick={handleBackdropClick}
      ref={modalRef}
    >
      <div className="shadow-lg rounded-lg lg:p-8 p-4 lg:w-[80%] bg-white w-[100%] flex" style={{
        backgroundImage: `url(${herobg})`,
        backgroundSize: "cover",
      }}>
        <div className='hidden lg:block'>
          <h2 className="text-2xl font-bold text-blue-900 mb-6">How Web_Bocket Classes helps you in your Career</h2>
          <div className="grid gap-10 grid-cols-2">
            <img src={img1} alt='img' />
            <img src={img2} alt='img' />
            <img src={img3} alt='img' />
            <img src={img4} alt='img' />
            <img src={img5} alt='img' />
            <img src={img6} alt='img' />
          </div>
        </div>
        <div className='lg:w-[60%] flex flex-col justify-center w-[100%]'>
          <div className="flex flex-col md:flex-row items-center mb-4 relative">
            <IoMdClose size={25} className="cursor-pointer absolute lg:right-0 lg:-top-6 top-0 right-0 text-black" onClick={onClose} />
            <img src={img7} alt="Instructor Icon" className="w-12 h-12" />
            <div className="ml-4">
              <h2 className="lg:text-4xl font-bold text-blue-900">Want To Become A Part Of Web_Bocket Software Pvt.Ltd</h2>
              <p className="text-gray-500">Get details and latest update</p>
            </div>
          </div>

          <form onSubmit={handleSubmit}>
            <h2 className="text-lg sm:text-xl font-bold mb-4 text-center">
              Apply for {job.title}
            </h2>
            {/* Full Name Field */}
            <div className="mb-4">
              <label className="block text-sm font-medium mb-1" htmlFor="name">
                Full Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="w-full px-3 py-2 border rounded-md text-sm sm:text-base"
                required
              />
            </div>

            {/* Email Address Field */}
            <div className="mb-4">
              <label className="block text-sm font-medium mb-1" htmlFor="email">
                Email Address
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full px-3 py-2 border rounded-md text-sm sm:text-base"
                required
              />
            </div>

            {/* Phone Number Field */}
            <div className="mb-4">
              <label className="block text-sm font-medium mb-1" htmlFor="phone">
                Phone Number
              </label>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                className="w-full px-3 py-2 border rounded-md text-sm sm:text-base"
                required
                placeholder="Enter your phone number"
              />
            </div>

            {/* Resume Upload Field */}
            <div className="mb-4">
              <label className="block text-sm font-medium mb-1" htmlFor="resume">
                Upload Resume (JPG/PNG)
              </label>
              <input
                type="file"
                id="resume"
                name="resume"
                onChange={handleChange}
                className="w-full px-3 py-2 border rounded-md text-sm sm:text-base"
                required
                accept=".jpg, .jpeg, .png" // Only allow JPG and PNG formats
              />
            </div>

            {/* Submit and Close Buttons */}
            <div className="flex flex-col sm:flex-row justify-between mt-4">
              <button
                type="submit"
                className="py-2 px-4 bg-blue-600 text-white rounded-md mb-2 sm:mb-0 sm:w-auto"
              >
                Submit Application
              </button>
              <button
                type="button"
                onClick={onClose}
                className="py-2 px-4 bg-gray-600 text-white rounded-md sm:w-auto"
              >
                Close
              </button>
            </div>
          </form>
        </div>
      </div>

    </div>
  );
};

export default JobApplicationModal;
