import React from "react";
import HeroSection from "../../Components/Home/HeroSection";
import FeaturedCourses from "../../Components/Home/FeaturedCourses";
import FeaturedInstructor from "../../Components/Home/FeaturedInstructor";
import TrustedBy from "../../Components/Home/TrustedBy";
import WantToShare from "../../Components/Home/WantToShare";
import OurTestimonials from "../../Components/Home/OurTestimonials";
import MasterTheSkills from "../../Components/Home/MasterTheSkills";
import UnlockYour from "../../Components/Home/UnlockYour";
import LatestTechBlogs from "../../Components/Home/LatestTechBlogs";
import BecomeAnInstructor from "../../Components/Home/BecomeAnInstructor";
import FooterBanner from "../../Components/ReusableComponents/FooterBanner";
import WhatsappComponent from "../../Components/messagesmodal/WhatsappComponen";
import CallButtonComponent from "../../Components/messagesmodal/CallButtonComponent";
import OffersZone from "../../Components/Home/OffersZone";
import OfferCounter from "../../Components/Home/OfferCounter";

const Home = () => {
  return (
    <div className="overflow-x-hidden">
      <HeroSection />
      <OfferCounter endDate="2024-11-10T23:59:59" />
      <OffersZone/>
      <UnlockYour />
      <FeaturedCourses />
      <MasterTheSkills />
      <FeaturedInstructor />
      <TrustedBy />
      <WantToShare />
      <OurTestimonials />
      <LatestTechBlogs />
      <BecomeAnInstructor />
      <FooterBanner/>
      <WhatsappComponent/>
      <CallButtonComponent/>
    </div>
  );
};

export default Home;
