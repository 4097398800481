import React from 'react'
import img1 from '../../Assets/AboutUsImg/img1.svg'
import img2 from '../../Assets/AboutUsImg/img2.svg'
import bg from '../../Assets/AboutUsImg/bg.png'
import logo from '../../Assets/AboutUsImg/logo.svg'
import logo1 from '../../Assets/AboutUsImg/logo1.svg'
import logo2 from '../../Assets/AboutUsImg/logo2.svg'
import logo3 from '../../Assets/AboutUsImg/logo3.svg'
import { useIntersectionObserver } from '../../Assets/CustomHook/CustomHook'
import CountUp from "react-countup";
const OurVision = () => {
    const [ref, isVisible] = useIntersectionObserver({
        threshold: 0.1,
        triggerOnce: true,
      });
    return (
        <>
            <div className="py-14 md:px-16 px-6 overflow-hidden" style={{
                backgroundImage: `url(${bg})`,
                backgroundSize: "cover",
            }}>
                <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-5 items-center" ref={ref}>
                    <div className="flex items-center md:flex-row flex-col justify-center space-x-5" data-aos="fade-up">
                        <img src={logo} alt='img' />
                        <div className='text-center'>
                            <div className="text-4xl font-bold">  {isVisible && <CountUp start={0} end={10000} duration={5} />}+</div>
                            <div className="text-lg">Student Admission</div>
                        </div>
                    </div>
                    <div className="flex items-center md:flex-row flex-col justify-center space-x-5" data-aos="fade-down">
                        <img src={logo1} alt='img' />
                        <div className='text-center'>
                            <div className="text-4xl font-bold">{isVisible && <CountUp start={0} end={500} duration={5} />}+</div>
                            <div className="text-lg">Project Completed</div>
                        </div>
                    </div>
                    <div className="flex items-center md:flex-row flex-col justify-center space-x-5" data-aos="fade-right">
                        <img src={logo2} alt='img' />
                        <div className='text-center'>
                            <div className="text-4xl font-bold">{isVisible && <CountUp start={0} end={15} duration={5} />}+</div>
                            <div className="text-lg">MOU Signed Colleges</div>
                        </div>
                    </div>
                    <div className="flex items-center md:flex-row flex-col justify-center space-x-5" data-aos="fade-left">
                        <img src={logo3} alt='img' />
                        <div className='text-center'>
                            <div className="text-4xl font-bold">{isVisible && <CountUp start={0} end={100} duration={5} />}%</div>
                            <div className="text-lg">Placement Call</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:mx-16 mx-6 py-10">
                <div className="bg-white p-6 rounded-lg shadow-md border border-gray-200" data-aos="fade-up-right">
                    <div className="flex items-center justify-center md:justify-start mb-4">
                        <img src={img1} alt='img' />
                    </div>
                    <h2 className="text-xl font-bold mb-2">OUR MISSION</h2>
                    <p>Our Mission at Web_Bocket Classes is to Empower Aspiring Software Professionals by Providing Cutting Edge Technical Education and Comprehensive Career Placement Services.</p>
                </div>
                <div className="bg-white p-6 rounded-lg shadow-md border border-gray-200"  data-aos="fade-up-left">
                    <div className="flex items-center justify-center md:justify-start mb-4">
                        <img src={img2} alt='img' />
                    </div>
                    <h2 className="text-xl font-bold mb-2">OUR VISION</h2>
                    <p>Our vision is to be a leading institution in the education industry, renowned for our unparalleled success in shaping the careers of future software engineers.</p>
                </div>
            </div>

        </>
    )
}

export default OurVision