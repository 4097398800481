import React from 'react'

const GetFromUs = () => {
    return (
        <div className="bg-gradient-to-r from-pink-100 to-blue-100 overflow-hidden" >
            <div className="mx-auto py-16 px-4 sm:px-6 lg:px-8">
                <div className="text-center">
                    <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
                        Benefits that You Get from Us !
                    </h2>
                    <p className="mt-4 text-lg leading-6 text-gray-500 md:max-w-[50%] mx-auto">
                        Experience unmatched benefits, including personalized support, high-quality service, timely delivery, innovative solutions, and a dedicated team committed to your success.
                    </p>
                </div>
                <div className="mt-12 grid gap-8 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 md:mx-16 mx-6">
                    <div className="bg-white p-6 rounded-lg shadow-lg">
                        <div className="text-4xl font-bold text-end text-gray-900">01</div>
                        <h3 className="mt-4 text-xl font-semibold text-gray-900">Flexible Learning Schedule</h3>
                        <p className="mt-2 text-gray-500">Fit your coursework around your existing commitments and obligations.</p>
                    </div>
                    <div className="bg-white p-6 rounded-lg shadow-lg">
                        <div className="text-4xl font-bold text-end text-gray-900">02</div>
                        <h3 className="mt-4 text-xl font-semibold text-gray-900">Expert Instruction</h3>
                        <p className="mt-2 text-gray-500">Learn from industry experts who have hands-on experience in design and development.</p>
                    </div>
                    <div className="bg-white p-6 rounded-lg shadow-lg">
                        <div className="text-4xl font-bold text-end text-gray-900">03</div>
                        <h3 className="mt-4 text-xl font-semibold text-gray-900">Diverse Course Offerings</h3>
                        <p className="mt-2 text-gray-500">Explore a wide range of design and development courses covering various topics.</p>
                    </div>
                    <div className="bg-white p-6 rounded-lg shadow-lg">
                        <div className="text-4xl font-bold text-end text-gray-900">04</div>
                        <h3 className="mt-4 text-xl font-semibold text-gray-900">Updated Curriculum</h3>
                        <p className="mt-2 text-gray-500">Access courses with up-to-date content reflecting the latest trends and industry practices.</p>
                    </div>
                    <div className="bg-white p-6 rounded-lg shadow-lg">
                        <div className="text-4xl font-bold text-end text-gray-900">05</div>
                        <h3 className="mt-4 text-xl font-semibold text-gray-900">Practical Projects and Assignments</h3>
                        <p className="mt-2 text-gray-500">Develop a portfolio showcasing your skills and abilities to potential employers.</p>
                    </div>
                    <div className="bg-white p-6 rounded-lg shadow-lg">
                        <div className="text-4xl font-bold text-end text-gray-900">06</div>
                        <h3 className="mt-4 text-xl font-semibold text-gray-900">Interactive Learning Environment</h3>
                        <p className="mt-2 text-gray-500">Collaborate with fellow learners, exchanging ideas and feedback to enhance your understanding.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GetFromUs