import React, { useRef, useState } from 'react';
import emailjs from 'emailjs-com';
import { IoMdClose } from "react-icons/io";
// import img1 from '../../Assets/logo/mern.png';
// import img2 from '../../Assets/logo/java.png';
// import img3 from '../../Assets/logo/student.png';
// import img4 from '../../Assets/logo/field.png';
// import img5 from '../../Assets/logo/marketting.png';
// import img6 from '../../Assets/logo/srexecutive.png';
import img7 from '../../Assets/logo/logo.png';
import herobg from '../../Assets/Homeimgs/FeaturedCoursesbackground.png';
import img8 from '../../Assets/Homeimgs/java.png'
import img9 from '../../Assets/Homeimgs/node.png'
import img10 from '../../Assets/Homeimgs/python.png'
import img11 from '../../Assets/Homeimgs/ai.png'
import img12 from '../../Assets/Homeimgs/ui-ux.png'
import img13 from '../../Assets/Homeimgs/dsa.png'
const AppointmentModal = ({ onClose }) => {
    const modalRef = useRef();
    const formRef = useRef();
    const [loading, setLoading] = useState(false);
    const [formValues, setFormValues] = useState({ name: '', email: '', phone: '', city: '' });
    const [errors, setErrors] = useState({});

    const closeModal = (e) => {
        if (modalRef.current === e.target) {
            onClose();
        }
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formValues.name) newErrors.name = "First Name is required";
        if (!formValues.email) newErrors.email = "Email is required";
        if (!formValues.phone) newErrors.phone = "Phone Number is required";
        if (!formValues.city) newErrors.city = "City is required";
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
        console.log(setFormValues)
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!validateForm()) return;

        setLoading(true);
        emailjs.sendForm('service_xyvcc1e', 'template_voinpgp', formRef.current, 'z7shN8aJoJopttBxt')
            .then((result) => {
                console.log(result.text);
                setLoading(false);
                formRef.current.reset();
                setFormValues({ name: '', email: '', phone: '', city: '' });
                setErrors({});
            })
            .catch((error) => {
                console.error(error.text);
                setLoading(false);
            });
    };

    return (
        <div ref={modalRef} className="fixed inset-0 z-50 bg-gray-800 bg-opacity-75 flex items-center justify-center p-4 sm:p-6" onClick={closeModal}>
            <div className="shadow-lg rounded-lg bg-white lg:p-8 p-4 md:space-x-10 lg:w-[70%] w-[100%] flex" style={{
                backgroundImage: `url(${herobg})`,
                backgroundSize: "cover",
            }}>
                <div className='w-[30%] hidden lg:block'>
                    <h2 className="text-2xl font-bold text-blue-900 mb-6">"Web_Bocket Classes boosts careers with skills, mentorship, placements."</h2>
                    <div className="grid gap-2 grid-cols-2">
                        <div className='bg-white flex items-center justify-center p-5 '>
                            <img src={img9} alt='img' className='w-[50%] object-cover' />
                        </div>
                        <div className='bg-white flex items-center justify-center p-5 '>
                            <img src={img8} alt='img' className='w-[50%] object-cover' />
                        </div>
                        <div className='bg-white flex items-center justify-center p-5 '>
                            <img src={img10} alt='img' className='w-[50%] object-cover' />
                        </div>
                        <div className='bg-white flex items-center justify-center p-5 '>
                            <img src={img11} alt='img' className='w-[50%] object-cover' />
                        </div>
                        <div className='bg-white flex items-center justify-center p-5 '>
                            <img src={img13} alt='img' className='w-[50%] object-cover' />
                        </div>

                        <div className='bg-white flex items-center justify-center p-5 '>
                            <img src={img12} alt='img' className='w-[50%] object-cover' />
                        </div>

                        {/* <img src={img2} alt='img' />
                        <img src={img3} alt='img' />
                        <img src={img4} alt='img' />
                        <img src={img5} alt='img' />
                        <img src={img6} alt='img' /> */}
                    </div>

                    <div className="mt-6">
                        <a
                            href="../../Assets/Homeimgs/Brochure.pdf"
                            download
                        >
                            <button className="bg-yellow-500 text-white px-4 py-2 rounded-lg flex items-center">
                                <span>Download Brochure Here</span>
                                <i className="fas fa-download ml-2"></i>
                            </button>
                        </a>
                    </div>

                </div>
                <div className='lg:w-[70%] flex flex-col justify-center w-[100%]'>
                    <div className="flex flex-col md:flex-row items-center mb-4 relative">
                        <IoMdClose size={25} className="cursor-pointer absolute lg:right-0 lg:top-0 top-0 right-0 text-black" onClick={onClose} />
                        <img src={img7} alt="Instructor Icon" className="w-12 h-12" />
                        <div className="ml-4">
                            <h2 className="lg:text-4xl font-bold text-blue-900">Want To Become A Software Developer Intern</h2>
                            <p className="text-gray-500">Get details and latest update</p>
                        </div>
                    </div>
                    <form ref={formRef} onSubmit={handleSubmit} className="w-full">
                        <div className="space-y-4 sm:space-y-6 mt-6 sm:mt-8">
                            <input
                                type="text"
                                className="w-full border p-3 rounded-md text-black"
                                placeholder="First Name"
                                name="name"
                                value={formValues.name}
                                onChange={handleChange}
                            />
                            {errors.name && <p className="text-red-500 text-sm">{errors.name}</p>}

                            <input
                                type="email"
                                className="w-full border p-3 rounded-md text-black"
                                placeholder="Email"
                                name="email"
                                value={formValues.email}
                                onChange={handleChange}
                            />
                            {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}

                            <input
                                type="tel"
                                className="w-full border p-3 rounded-md text-black"
                                placeholder="Phone Number"
                                name="phone"
                                value={formValues.phone}
                                onChange={handleChange}
                            />
                            {errors.phone && <p className="text-red-500 text-sm">{errors.phone}</p>}

                            <input
                                type="text"
                                className="w-full border p-3 rounded-md text-black"
                                placeholder="City"
                                name="city"
                                value={formValues.city}
                                onChange={handleChange}
                            />
                            {errors.city && <p className="text-red-500 text-sm">{errors.city}</p>}

                            <div className="flex items-center justify-between mt-4">
                                <button className="bg-white text-black px-4 py-2 rounded shadow-md hover:bg-gray-200 transition duration-200" type="submit" disabled={loading}>
                                    {loading ? 'Sending...' : 'Submit'}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AppointmentModal;
