import React from "react";
import img1 from "../../Assets/Homeimgs/image-1.png";
import img2 from "../../Assets/Homeimgs/image-2.png";
import img3 from "../../Assets/Homeimgs/image-3.png";
import img4 from "../../Assets/Homeimgs/image-4.png";
import img5 from "../../Assets/Homeimgs/image-5.png";
import img6 from "../../Assets/Homeimgs/image-14.png";
import img7 from "../../Assets/Homeimgs/image-6.png";
import img8 from "../../Assets/Homeimgs/image-7.png";
import img9 from "../../Assets/Homeimgs/image-8.png";
import img10 from "../../Assets/Homeimgs/image-9.png";
import img11 from "../../Assets/Homeimgs/image-10.png";
import img12 from "../../Assets/Homeimgs/image-11.png";
import img13 from "../../Assets/Homeimgs/image-12.png";
import img14 from "../../Assets/Homeimgs/image-13.png";
import img15 from "../../Assets/Homeimgs/image-15.png";
const TrustedBy = () => {
  return (
    <div>
      <div>
        <div className="text-center py-12" data-aos="fade-up"
          data-aos-duration="1000">
          <p className="text-[#5989C8] italic font-semibold text-lg md:text-xl">
            Trusted By
          </p>
          <h1 className="text-2xl md:text-4xl font-bold text-gray-900 mt-5">
            200+ Leading Universities And Colleges
          </h1>
        </div>

        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6 md:mx-16 mx-6">
          {/* {universities.map((university, index) => (
            <img
              data-aos={university.animation}
              src={university.logo}
              className="mx-auto"
              alt={`${university.name} logo`}
            />
          ))} */}
          <img src={img1} alt="img" data-aos="fade-up" />
          <img src={img2} alt="img" data-aos="fade-down" />
          <img src={img3} alt="img" data-aos="fade-left" />
          <img src={img4} alt="img" data-aos="fade-right" />
          <img src={img5} alt="img" data-aos="zoom-in" />
          <img src={img6} alt="img" data-aos="zoom-out" />
          <img src={img7} alt="img" data-aos="flip-left" />
          <img src={img8} alt="img" data-aos="flip-right" />
          <img src={img9} alt="img" data-aos="slide-up" />
          <img src={img10} alt="img" data-aos="slide-down" />
          <img src={img11} alt="img" data-aos="slide-left" />
          <img src={img12} alt="img" data-aos="slide-right" />
          <img src={img13} alt="img" data-aos="fade-up-right" />
          <img src={img14} alt="img" data-aos="fade-up-left" />
          <img src={img15} alt="img" data-aos="flip-up" className="hidden md:block" />
        </div>
      </div>
    </div>
  );
};

export default TrustedBy;
