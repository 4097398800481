import React from "react";

const ContactForm = () => {
  return (
    <div className=" tracking-wide flex justify-center items-center bg-[#faf2f2] p-6 md:p-12 min-h-screen">
      <div className="w-full max-w-7xl bg-white p-[1.5rem] lg:p-12 rounded-lg shadow-lg">
        <h2 className="text-center text-2xl md:text-3xl font-semibold text-gray-900 mb-4">
          Send us Message
        </h2>
        <p className="font-light text-center text-[#737588] mb-8 px-3 lg:px-20  mt-4">
          Do you have questions, need assistance, or want more information about
          our courses or internship programs? We're here to help! Simply fill
          out the form below, and a member of our team will get back to you as
          soon as possible.
        </p>

        <form className="space-y-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <input
              type="text"
              placeholder="Name"
              className="p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <input
              type="text"
              placeholder="Phone Number"
              className="p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <input
              type="email"
              placeholder="Email ID"
              className="p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <input
              type="text"
              placeholder="Subject"
              className="p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            <textarea
              placeholder="Message"
              rows="4"
              className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            ></textarea>
          </div>
          <div className="flex justify-center">
            <button
              type="submit"
              className="bg-[#044a94] text-white px-14 tracking-wide py-3 rounded-lg font-medium hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              Send
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
