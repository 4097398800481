import React, { useState, useEffect } from 'react';
import './OffersZone.css';
import ofr1 from '../../Assets/Homeimgs/Courses1.png';
import ofr2 from '../../Assets/Homeimgs/Courses2.png';
import ofr3 from '../../Assets/Homeimgs/Courses3.png';

const offers = [
    { id: 1, name: 'Haircut Special', icon: ofr1 },
    { id: 2, name: 'Nail Art', icon: ofr2 },
    { id: 3, name: 'Spa Treatment', icon: ofr3 },
    { id: 4, name: 'Hair Coloring', icon: ofr1 },
    { id: 5, name: 'Facial Care', icon: ofr2 },
    { id: 6, name: 'Massage Therapy', icon: ofr3 },
];

const OffersZone = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const getVisibleOffers = () => {
        return offers.slice(currentIndex, currentIndex + 3);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) =>
                prevIndex + 3 >= offers.length ? 0 : prevIndex + 3
            );
        }, 2000); 

        return () => clearInterval(interval); 
    }, []);

    return (
        <div className="bg-gray-100 py-10 relative px-6">
            <div className="text-center pb-6">
                <h1  className="text-2xl md:text-4xl font-bold text-gray-900 mt-2" data-aos="fade-down-right">Offers Zone</h1>
            </div>

            <div className="offers-wrapper">
                {getVisibleOffers().map((offer) => (
                    <div key={offer.id} className="offer-item">
                        <img
                            src={offer.icon}
                            className='object-contain'
                            alt="ofr-img"
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default OffersZone;
