import React from "react";
import { PhoneCall } from "lucide-react";
import FooterBannerImg from "../../Assets/ContactImg/FooterBanImg.png";
import phoneIcon from "../../Assets/CareersImg/Background+Border.png";

const FooterBanner = () => {
 
  return (
    <div
      style={{ backgroundImage: `url(${FooterBannerImg})` }}
      data-aos="fade-down-left"
      className="max-w-6xl mx-auto text-white px-4 py-4  md:px-10 md:py-16 bg-cover bg-center rounded-lg"
    >
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row items-center justify-between gap-2 md:gap-6 lg:gap-0 space-y-4 md:space-y-0">
          {/* Left side content */}
          <div className="flex flex-col space-y-2 ">
            <p className="text-sm md:text-base text-center lg:text-start ">
              Need Any Consultations ?
            </p>
            <h2 className="text-base md:text-2xl lg:text-[32px] font-semibold text-center lg:text-start">
              We're Ready to Enhance Your Career
            </h2>
          </div>

          {/* Right side content */}
          <div className="flex flex-col sm:flex-row items-center space-y-3 sm:space-y-0 sm:space-x-4">
            {/* Get Free Quote Button */}
            <button className="bg-[#f8b003] hover:bg-[#e9aa17] text-[16px] font-semibold text-white px-8 py-4 rounded-md transition-colors duration-200 text-sm md:text-base">
              Get Free Quote →
            </button>

            {/* Phone Number */}
            <div className="flex items-center space-x-2">
              <div className="bg-white rounded-full">
                {/* <PhoneCall className="h-5 w-5 text-blue-700" /> */}
                <img src={phoneIcon} alt="No Image" className="" />
              </div>
              <div className="flex flex-col">
                <span className="text-xs md:text-sm text-gray-200 mb-1">
                  Hotline
                </span>
                <a
                  href="tel:+916372833928"
                  className="text-sm md:text-base font-medium hover:text-gray-200 transition-colors duration-200"
                >
                  +91 6372 833 928
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterBanner;
