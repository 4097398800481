import React from 'react';
import img1 from '../../Assets/Homeimgs/OnlineCourses.svg';
import img2 from '../../Assets/Homeimgs/ExpertTutors.svg';
import img3 from '../../Assets/Homeimgs/certificate-icon.svg';
import img4 from '../../Assets/Homeimgs/OnlineStudents.svg';
import img5 from '../../Assets/Homeimgs/z.svg';
import img6 from '../../Assets/Homeimgs/AbstractLine.svg'
import { useIntersectionObserver } from '../../Assets/CustomHook/CustomHook';
import CountUp from 'react-countup';
const UnlockYour = () => {
    const [ref, isVisible] = useIntersectionObserver({
        threshold: 0.1,
        triggerOnce: true,
      });
    return (
        <div className="px-4 md:px-16 bg-[#fff8f8]">
            <div className="py-8 md:py-12">
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6 mb-8 md:mb-12" ref={ref}>
                    <div className="bg-white p-4 sm:p-6 rounded-lg shadow-md flex items-center space-x-4"  data-aos="zoom-in">
                        <img src={img1} alt="img" className="w-12 h-12 sm:w-14 sm:h-14" />
                        <div>
                            <h3 className="text-lg sm:text-xl font-semibold">{isVisible && <CountUp start={0} end={2000} duration={5} />}+</h3>
                            <p className="text-gray-600 text-sm sm:text-base">Student Addmission</p>
                        </div>
                    </div>
                    <div className="bg-white p-4 sm:p-6 rounded-lg shadow-md flex items-center space-x-4"  data-aos="zoom-in-up">
                        <img src={img2} alt="img" className="w-12 h-12 sm:w-14 sm:h-14" />
                        <div>
                            <h3 className="text-lg sm:text-xl font-semibold">{isVisible && <CountUp start={0} end={10} duration={5} />}+</h3>
                            <p className="text-gray-600 text-sm sm:text-base">Online/Offline Courses</p>
                        </div>
                    </div>
                    <div className="bg-white p-4 sm:p-6 rounded-lg shadow-md flex items-center space-x-4" data-aos="zoom-in-down">
                        <img src={img3} alt="img" className="w-12 h-12 sm:w-14 sm:h-14" />
                        <div>
                            <h3 className="text-lg sm:text-xl font-semibold">{isVisible && <CountUp start={0} end={500} duration={5} />}+</h3>
                            <p className="text-gray-600 text-sm sm:text-base">Student Placed</p>
                        </div>
                    </div>
                    <div className="bg-white p-4 sm:p-6 rounded-lg shadow-md flex items-center space-x-4" data-aos="zoom-in-left">
                        <img src={img4} alt="img" className="w-12 h-12 sm:w-14 sm:h-14" />
                        <div>
                            <h3 className="text-lg sm:text-xl font-semibold">{isVisible && <CountUp start={0} end={800} duration={5} />}+</h3>
                            <p className="text-gray-600 text-sm sm:text-base">Project Completed</p>
                        </div>
                    </div>
                </div>
                <div className="text-center relative" data-aos="flip-up">
                    <img src={img6}  alt='img' className='md:block hidden absolute left-40 -top-8'/>
                    <div className="inline-flex bg-white items-center space-x-3 p-3 md:p-8 rounded-lg shadow-md mb-3 md:mb-4">
                        <img src={img5} alt="img" className="w-10 h-10 sm:w-12 sm:h-12" data-aos="zoom-out-up"/>
                        <h1 className="text-3xl sm:text-4xl md:text-5xl font-semibold tracking-wide" data-aos="zoom-out-right">
                            <span className="text-[#0055AD]">Unlock</span> Your Creative Potential
                        </h1>
                    </div>
                    <p className="text-2xl tracking-wide md:text-4xl font-normal text-black mt-2" data-aos="zoom-out-left">
                        With Online and Offline Development Courses.
                    </p>
                    <p className="text-gray-600 text-base sm:text-lg md:text-xl mt-4 font-medium"  data-aos="fade-up-left">
                        Learn from Industry Experts and Enhance Your Skills.
                    </p>
                </div>
            </div>
        </div>
    );
};
export default UnlockYour;