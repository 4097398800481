import React from "react";
import CareerOne from "../../Assets/CareersImg/CareersOne.png";
const CareerEnhance = () => {
  return (
    <div className="bg-white overflow-hidden px-6 md:px-16 py-12 md:py-16">
      <div className="  ">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 items-center">
          {/* Left Content Section */}
          <div className="space-y-6 md:pr-8">
            <h1 className="text-[24px] lg:text-[40px] font-semibold text-navy-900 leading-tight">
              Enhance your Career at Webbocket Classes
            </h1>
            <p className="text-gray-600 text-lg leading-relaxed max-w-2xl font-normal">
              At Web_Bocket Classes we believe in the power of education to
              change lives. Our mission is to create meaningful learning
              experiences that inspire growth, innovation, and personal
              transformation. We are looking for talented, passionate
              individuals who share our commitment to making high-quality
              education accessible to everyone.
            </p>
          </div>

          {/* Right Image Grid Section */}
          <div className="relative  h-full ">
            <img
              src={CareerOne}
              alt="No Image"
              className=" h-full w-full bg-contain"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CareerEnhance;
