import React from "react";

const ContactUsMapContainer = () => {
  return (
  <>
  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3743.130886432441!2d85.78234237469417!3d20.25340581395823!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a19098aa66063cd%3A0x55d0623fa712cd29!2sWeb_Bocket!5e0!3m2!1sen!2sin!4v1730462958198!5m2!1sen!2sin" width="100%" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
  </>
  );
};

export default ContactUsMapContainer;
