import React from "react";
import whatsapp from "../../Assets/logo/WhatsApp.svg";
const WhatsappComponent = () => {
  const openMessageModal = () => {
    const phoneNumber = "9861934932";
    const whatsappUrl = `https://wa.me/${phoneNumber}`;
    window.open(whatsappUrl, "_blank");
  };
  return (
    <button
      className="fixed right-4 bottom-4 z-40  rounded-full shadow-lg  hover:scale-105 ease-in-out duration-300 focus:outline-none transition-transform transform-gpu"
      onClick={openMessageModal}
    >
      <img src={whatsapp} alt="" className="w-[4rem] object-cover" />
      {/* <IoLogoWhatsapp size={32} color="#10b416" /> */}
    </button>
  );
};

export default WhatsappComponent