import React from "react";
import phoneIcon from "../../Assets/logo/phone.jpg";

const CallButtonComponent = () => {
  const phoneNumber = "9861934932";

  return (
    <button
      className="fixed left-4 bottom-4 z-40 rounded-full shadow-lg hover:scale-105 ease-in-out duration-300 focus:outline-none transition-transform transform-gpu"
      onClick={() => (window.location.href = `tel:${phoneNumber}`)}
    >
      <img src={phoneIcon} alt="Call" className="w-[4rem] rounded-full object-cover" />
    </button>
  );
};

export default CallButtonComponent;
