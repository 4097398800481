import { useState } from "react";
import { MdArrowForwardIos } from "react-icons/md";
import LASTIMG from "../../Assets/CareersImg/CarrersLastImg.png";

const FaqSectionService = ({ faqs }) => {
  console.log("---i am faqs--->", faqs);
  const [openFAQ, setOpenFAQ] = useState(null);

  const toggleDropdown = (id) => {
    setOpenFAQ(openFAQ === id ? null : id);
  };

  return (
    <div className="lg:px-16 mx-auto py-16 px-4 text-center bg-[#FDF2F8]">
      <div className="flex flex-col lg:flex-row justify-center items-center gap-6 lg:gap-0">
        <div className="space-y-4 text-left w-full lg:w-[50%]">
          {faqs.map((faq) => (
            <div key={faq.id} className="bg-white shadow-xl p-3 rounded-md">
              <div
               
                className="p-4 rounded-lg flex justify-between items-center cursor-pointer"
                onClick={() => toggleDropdown(faq.id)}
              >
                <h2
                  className={`text-[17.14px] font-medium ${
                    openFAQ === faq.id ? "text-yellow-500" : "text-black"
                  }`}
                >
                  {faq.question}
                </h2>
                <MdArrowForwardIos
                  className={`fas ${
                    openFAQ === faq.id ? "rotate-90" : "rotate-0"
                  } transition-transform duration-300`}
                />
              </div>
              {openFAQ === faq.id && (
                <div className="p-4 rounded-lg text-[15.43px] font-normal">
                  <p>{faq.answer}</p>
                </div>
              )}
            </div>
          ))}
        </div>

        {/* Images Section */}
        <div className="w-full lg:w-[50%]">
          <h1 className="text-[24px] lg:text-[40px]  font-semibold">
            Frequently Asked Questions
          </h1>
          <div className="h-fit">
            <img src={LASTIMG} alt="No Image" className="h-full" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqSectionService;
