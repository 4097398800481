import React from 'react';
import blogImage1 from '../../Assets/Homeimgs/blog1.png';
import blogImage2 from '../../Assets/Homeimgs/blog2.png';
import blogImage3 from '../../Assets/Homeimgs/blog3.png';
import img1 from '../../Assets/Homeimgs/react.svg'
import img2 from '../../Assets/Homeimgs/db.svg'
import img3 from '../../Assets/Homeimgs/javascript.svg'
import img4 from '../../Assets/Homeimgs/tailwind.svg'
import img5 from '../../Assets/Homeimgs/node.svg'
import img6 from '../../Assets/Homeimgs/python.svg'
import img7 from '../../Assets/Homeimgs/jupyter.svg'
import img8 from '../../Assets/Homeimgs/flask.svg'
import img9 from '../../Assets/Homeimgs/figma.png'
import img10 from '../../Assets/Homeimgs/canva.svg'
import blogbg from '../../Assets/Homeimgs/blogbg.png'
const LatestTechBlogs = () => {
  return (
    <div className='p-6' style={{
      backgroundImage: `url(${blogbg})`,
      backgroundSize: "cover",
    }}>
      {/* Header */}
      <div className="text-center mb-8">
        <h1 className="text-2xl md:text-4xl font-bold text-black mt-2 tracking-wide" data-aos="zoom-out-right">Latest Tech Blogs</h1>
        <p className="text-gray-600 mt-4 tracking-wide md:max-w-[60%] mx-auto" data-aos="zoom-out-left">
          Stay updated with our Latest Tech Blogs! Explore in-depth articles on emerging technologies, industry trends, and innovative solutions. Perfect for tech enthusiasts and professionals eager to stay ahead.
        </p>
      </div>

      {/* Blog Cards */}
      <div className="grid grid-cols-3">
        <img src={blogImage1} alt='img' data-aos="zoom-in-right" />
        <img src={blogImage2} alt='img' data-aos="zoom-out" />
        <img src={blogImage3} alt='img' data-aos="zoom-out-up" />
      </div>

      {/* Technology Section */}
      <div className="text-center" data-aos="zoom-in-down">
        <h1 className="text-2xl tracking-wide md:text-4xl font-bold text-black mt-2 md:max-w-[50%] mx-auto" data-aos="zoom-in">
          Technology you learn, which helps you grow to the career
        </h1>
        <div className='grid grid-cols-5 gap-4 lg:grid-cols-10 w-[80%] mx-auto mt-10'>
          <img src={img1} className='mx-auto' alt='img' data-aos="flip-left" />
          <img src={img2} className='mx-auto' alt='img' data-aos="flip-right" />
          <img src={img3} className='mx-auto' alt='img' data-aos="flip-left" />
          <img src={img4} className='mx-auto' alt='img' data-aos="flip-right" />
          <img src={img5} className='mx-auto' alt='img' data-aos="flip-left" />
          <img src={img6} className='mx-auto' alt='img' data-aos="flip-right" />
          <img src={img7} className='mx-auto' alt='img' data-aos="flip-left" />
          <img src={img8} className='mx-auto' alt='img' data-aos="flip-right" />
          <img src={img9} className='mx-auto' alt='img' data-aos="flip-left" />
          <img src={img10} className='mx-auto' alt='img' data-aos="flip-right" />
        </div>
      </div>
    </div>
  );
};

export default LatestTechBlogs;
