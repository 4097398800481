import React from "react";
import Banner from "../../Components/ReusableComponents/Banner";
import ContactDetails from "../../Components/ContactUs/ContactDetails";
import ContactUsMapContainer from "../../Components/ContactUs/ContactUsMapContainer";
import ContactForm from "../../Components/ContactUs/ContactForm";
import FooterBanner from "../../Components/ReusableComponents/FooterBanner";

const Contact = () => {
  return (
    <div className="bg-[#FAF2F2]">
      <Banner page="Contact Us" path="contact" />
      <ContactDetails />
      <ContactUsMapContainer />
      <ContactForm />
      <FooterBanner />
    </div>
  );
};

export default Contact;
