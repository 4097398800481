import React from 'react'
import ApexChart from './ApexChart'
const QuarterlyReview = () => {
  return (
    <div className='text-center space-y-5 mb-10 ' >
        <h1 className="text-2xl md:text-4xl font-bold text-gray-900 mt-5" data-aos="fade-down-left">Our Quarterly Review</h1>
        <p className="text-gray-600 mt-4 md:max-w-[50%] mx-auto text-center tracking-wide" data-aos="flip-left">Our Quarterly Review highlights key achievements, growth insights, and strategic goals, fostering alignment and progress for the upcoming quarter.</p>
       <ApexChart/>
    </div>
  )
}

export default QuarterlyReview