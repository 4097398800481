import React, { useState, useEffect } from 'react';
import AppointmentModal from './AppointmentModal';

const OfferCounter = ({ endDate }) => {
  const [open, setOpen] = useState(false);

  const handleAddOpen = () => {
    setOpen(true);
  };

  const handleModalClose = () => {
    setOpen(false);
  };

  const calculateTimeLeft = () => {
    const difference = new Date(endDate) - new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  }, [timeLeft]);

  return (
    <div className="flex flex-col items-center justify-center bg-[#fee0dd] p-8 rounded-lg shadow-lg">
      <h1 className="text-lg font-bold mb-4">⚡ Limited-time offer! Sale ends in</h1>
      <div className="flex space-x-4">
        {timeLeft.days !== undefined ? (
          <>
            <div className="flex flex-col items-center bg-blue-600 text-white p-4 rounded-md">
              <span className="text-2xl font-bold">{timeLeft.days}</span>
              <span>Days</span>
            </div>
            <div className="flex flex-col items-center bg-blue-600 text-white p-4 rounded-md">
              <span className="text-2xl font-bold">{timeLeft.hours}</span>
              <span>Hours</span>
            </div>
            <div className="flex flex-col items-center bg-blue-600 text-white p-4 rounded-md">
              <span className="text-2xl font-bold">{timeLeft.minutes}</span>
              <span>Minutes</span>
            </div>
            <div className="flex flex-col items-center bg-blue-600 text-white p-4 rounded-md">
              <span className="text-2xl font-bold">{timeLeft.seconds}</span>
              <span>Seconds</span>
            </div>
          </>
        ) : (
          <p className="text-xl font-bold">Offer has ended!</p>
        )}
      </div>
      <button className="mt-4 bg-[#064585] text-white px-6 py-2 rounded-md" onClick={handleAddOpen}>Book now</button>
      {open && <AppointmentModal onClose={handleModalClose} />}
    </div>
  );
};

export default OfferCounter;
