import React, { useEffect, useState } from "react";
import herobg from "../../Assets/Homeimgs/herosectionbg.png";
import heroimg from "../../Assets/Homeimgs/heroimg.png";
import callicons from "../../Assets/Homeimgs/callicons.svg";
import AppointmentModal from "./AppointmentModal";

const HeroSection = () => {
  const [open, setOpen] = useState(false);

  const handleAddOpen = () => {
    setOpen(true);
  };

  const handleModalClose = () => {
    setOpen(false);
  };


  useEffect(() => {
    setTimeout(() => {
      setOpen(true);
    }, 30000)
    setTimeout(() => {
      setOpen(true);
    }, 90000)
    setTimeout(() => {
      setOpen(true);
    }, 150000)
  }, [])
  return (
    <div
      style={{
        backgroundImage: `url(${herobg})`,
        backgroundSize: "cover",
      }}
    >
      <div className="grid grid-cols-1 md:grid-cols-2 mx-4 md:mx-16">
        <div className="flex flex-col justify-center py-5 md:py-0s">
          <p className="text-[#5989C8] italic font-semibold md:text-xl" data-aos="fade-up">
            Own Your Future Learning New Skills On WBC
          </p>
          <div data-aos="flip-up" className="md:block hidden">
            <h1 className="text-4xl sm:text-5xl md:text-6xl font-semibold mt-4">
              Engaging &
            </h1>
            <h1 className="text-4xl sm:text-5xl md:text-6xl font-semibold mt-4">
              Accessible <span className="text-[#064585]">Offline</span>
            </h1>
            <h1 className="text-4xl sm:text-5xl md:text-6xl font-semibold mt-4">
              Online Courses For All
            </h1>
          </div>
          <div data-aos="flip-up" className="md:hidden block">
            <h1 className="text-4xl sm:text-3xl md:text-6xl font-semibold mt-4">
              Engaging &
            </h1>
            <h1 className="text-4xl sm:text-3xl md:text-6xl font-semibold mt-4">
              Accessible
            </h1>
            <h1 className="text-4xl sm:text-3xl md:text-6xl font-semibold mt-4">
              <span className="text-[#064585]">Offline </span>Online
            </h1>
            <h1 className="text-4xl sm:text-3xl md:text-6xl font-semibold mt-4">
              Courses For All
            </h1>
          </div>
          <p className="text-gray-800 font-normal text-base sm:text-lg md:text-xl mt-4" data-aos="zoom-in-down">
            Unlock your potential by signing up with Web_Bocket, The most
            affordable learning solution.
          </p>
          <div className="mt-8 flex flex-col sm:flex-row sm:items-center space-y-4 sm:space-y-0 sm:space-x-7">
            <button className="tracking-wide bg-[#064585] text-white py-4 px-6 rounded" onClick={handleAddOpen}>
              Talk to Us
            </button>
            <div className="flex items-center space-x-4">
              <img
                src={callicons}
                alt="img"
                className="w-7 h-7 md:w-8 md:h-8"
              />
              <div>
                <p className="text-gray-800 text-sm md:text-base">Contact Us</p>
                <p className="text-gray-800 font-semibold text-sm md:text-lg">
                  +91 9861934932
                </p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <img
            className="w-full md:w-[90%] mx-auto"
            src={heroimg}
            alt="person"
            data-aos="zoom-in-left"
          />
        </div>
      </div>
      {open && <AppointmentModal onClose={handleModalClose} />}
    </div>
  );
};

export default HeroSection;
