import Courses1 from "../../Assets/Homeimgs/top-MERN-Stack.webp";
import Courses2 from "../../Assets/Homeimgs/Courses2.png";
import Courses3 from "../../Assets/Homeimgs/Courses3.png";
import Courses4 from "../../Assets/Homeimgs/Courses4.png";
import Courses5 from "../../Assets/Homeimgs/Courses5.png";
import Courses6 from "../../Assets/Homeimgs/Courses6.png";
import place from '../../Assets/detailspage/place1.png';
import place1 from '../../Assets/detailspage/place2.png';
import place2 from '../../Assets/detailspage/place3.png';
import place3 from '../../Assets/detailspage/pace4.png';
const courses = [
  {
    id: 1,
    details: {
      aos: "flip-left",
      image: Courses1,
      category: "WEB DEVELOPMENT",
      name: "Full Stack Web Development in MERN",
      description: "Master MERN stack by building powerful web applications using MongoDB, Express, React, and Node.js. This hands-on course covers everything from frontend to backend development, deploying full-stack projects.",
      lesson: 30,
      students: 45,
      assignment: 3,
      rating: 4.7,
      reviews: 25,
      price: 8000,
      originalPrice: 12000.00,
      enrolled: "2k+",
    },
    overview: {
      description: "This course will take you from zero to mastery in MERN stack development. You'll learn the foundations of each technology, understand how they work together, and build complex applications from scratch. Includes best practices and tips from industry experts.",
      keyPoints1: [
        "MERN: MongoDB, Express, React.",
        "Develop full-stack applications efficiently.",
        "Integrate user authentication securely.",
        "Deploy projects to production.",
        "Optimize scalability and performance.",
      ],
      keyPoints2: [
        "Build APIs with Express.",
        "Create dynamic React frontends.",
        "Manage state using Redux.",
        "Utilize NoSQL with MongoDB.",
        "Ensure secure production applications."
      ]
    },
    courseContent: {
      lectures: "104 Lectures",
      courseFaq: [
        {
          id: "faqMern1",
          question: "What will I learn in the Full-Stack Web Development in MERN course?",
          answer: "The MERN stack course covers MongoDB for databases, Express.js and Node.js for backend development, and React for frontend development. You'll learn to build full-stack applications, including RESTful APIs, user authentication, and deployment."
        },
        {
          id: "faqMern2",
          question: "Are there prerequisites for this course?",
          answer: "Basic knowledge of HTML, CSS, and JavaScript is recommended. Familiarity with programming concepts will also be helpful, but the course is structured to be accessible to beginners."
        },
        {
          id: "faqMern3",
          question: "Will I work on projects during this course?",
          answer: "Yes, you’ll complete several projects, including a comprehensive full-stack application, to showcase your skills and build a portfolio."
        }
      ]
    },
    tutor: {
      pic:"",
      name: "Ramesh ch Mohapatra",
      title: "Full-Stack Developer",
      rating: 4.8,
      experience: "Founder & CTO of Web_Bocket 3+ year of teaching experience",
      skills: [
        "JavaScript", "React", "Node.js", "Express", "MongoDB",
        "REST APIs", "Redux", "Web Development", "Database Management",
        "Frontend Development", "Backend Development", "Git", "Heroku",
        "Cloud Deployment", "Full-Stack Application Development", "Problem Solving"
      ]
    },
    placementStudents: [
      {
        name: "Sambit Ku. Pradhan",
        company: "Web_Bocket",
        salary: "2.2 LPA",
        img: place
      },
      {
        name: "Sumant Ku. Sahu",
        company: "Web_Bocket",
        salary: "2.8 LPA",
        img: place1
      },
      {
        name: "Ashutosh Rout",
        company: "Tech Mahindra",
        salary: "3.5 LPA",
        img: place3
      },
      {
        name: "Bebika Patel",
        company: "Tech Mahindra",
        salary: "5.0 LPA",
        img: place2
      }
    ]
  },
  {
    id: 2,
    details: {
      aos: "flip-right",
      image: Courses2,
      category: "WEB DEVELOPMENT",
      name: "Full Stack Python",
      description: "Master full-stack development using Python with frameworks like Django and Flask. Build dynamic, robust web applications with backend and frontend integrations.",
      lesson: 20,
      students: 40,
      assignment: 2,
      rating: 4.6,
      reviews: 20,
      price: 10000,
      originalPrice: 14000.00,
      enrolled: "10k",
    },
    overview: {
      description: "This course will guide you through the essentials of full-stack development using Python. You’ll work with both Django and Flask frameworks, integrate frontend technologies, manage databases, and deploy your applications.",
      keyPoints1: [
        "Python: Versatile and powerful.",
        "Build web applications efficiently.",
        "Develop RESTful APIs easily.",
        "Utilize Django or Flask.",
        "Manage databases with ORM.",
      ],
      keyPoints2: [
        "Integrate user authentication securely.",
        "Implement responsive front-end frameworks.",
        "Deploy applications on cloud.",
        "Optimize application performance effectively.",
        "Ensure security in applications."
      ]
    },
    courseContent: {
      lectures: "98 Lectures",
      courseFaq: [
        {
          id: "faqPython1",
          question: "What are the prerequisites for Full-Stack Web Development in Python?",
          answer: "Basic knowledge of Python programming is recommended. The course covers web frameworks like Django or Flask, along with frontend skills, database management, and deployment strategies."
        },
        {
          id: "faqPython2",
          question: "What projects will I work on in this course?",
          answer: "You'll work on multiple projects, including a blog application, an e-commerce site, and a social media application, implementing end-to-end functionality and deployment."
        },
        {
          id: "faqPython3",
          question: "Will I learn both Django and Flask in this course?",
          answer: "Yes, you'll get an introduction to both Django and Flask, understanding their strengths and how to use them for different types of applications."
        }
      ]
    },
    tutor: {
      pic:"",
      name: "Ramesh ch Mohapatra",
      title: "Python Full-Stack Developer",
      rating: 4.7,
      experience: "Founder & CTO of Web_Bocket 3+ year of teaching experience",
      skills: [
        "Python", "Django", "Flask", "JavaScript", "HTML", "CSS", "SQL", "PostgreSQL",
        "MongoDB", "Web Development", "REST APIs", "Frontend Integration",
        "Heroku", "Git", "Cloud Deployment", "Software Architecture",
        "Problem Solving", "Debugging", "Security Best Practices"
      ]
    },
    placementStudents: [
      {
        name: "Sambit Ku. Pradhan",
        company: "Web_Bocket",
        salary: "5.0 LPA",
        img: place
      },
      {
        name: "Sumant Ku. Sahu",
        company: "Web_Bocket",
        salary: "5.5 LPA",
        img: place1
      },
      {
        name: "Ashutosh Rout",
        company: "Tech Mahindra",
        salary: "6.2 LPA",
        img: place3
      },
      {
        name: "Bebika Patel",
        company: "Tech Mahindra",
        salary: "5.8 LPA",
        img: place2
      }
    ]
  },
  {
    id: 3,
    details: {
      aos: "flip-left",
      image: Courses3,
      category: "WEB DEVELOPMENT",
      name: "Full Stack Web Development in Java",
      description: "Become a Full-Stack Java Developer by mastering backend and frontend development with Java, Spring Boot, and modern web technologies. Build scalable, high-performance applications from scratch.",
      lesson: 33,
      students: 50,
      assignment: 4,
      rating: 4.8,
      reviews: 30,
      price: 8000,
      originalPrice: 12500.00,
      enrolled: "10k",
    },
    overview: {
      description: "This comprehensive course will teach you everything you need to become a proficient Full-Stack Java Developer. You’ll work with backend frameworks like Spring Boot, integrate frontend technologies, and gain skills in building end-to-end applications.",
      keyPoints1: [
        "Java: Strong and reliable.",
        "Build web applications efficiently.",
        "Develop RESTful APIs easily.",
        "Utilize Spring Framework effectively.",
        "Manage databases with JPA.",
      ],
      keyPoints2: [
        "Integrate user authentication securely.",
        "Implement responsive front-end frameworks.",
        "Deploy applications on servers.",
        "Optimize performance and scalability.",
        "Ensure security in applications."
      ]
    },
    courseContent: {
      lectures: "110 Lectures",
      courseFaq: [
        {
          id: "faqJava1",
          question: "What does the Full-Stack Java course entail?",
          answer: "This course teaches full-stack development with Java, including backend frameworks like Spring Boot, frontend technologies such as JavaScript, and database management. You’ll gain skills in building robust, scalable web applications."
        },
        {
          id: "faqJava2",
          question: "Do I need prior experience with Java for this course?",
          answer: "Basic knowledge of Java is recommended, but the course includes a refresher on Java fundamentals to help beginners get up to speed."
        },
        {
          id: "faqJava3",
          question: "What projects will I complete during the course?",
          answer: "You'll work on multiple projects, including a blog platform, an e-commerce site, and a social network, implementing backend, frontend, and database functionality."
        }
      ]
    },
    tutor: {
      pic:"",
      name: "Ramesh ch Mohapatra",
      title: "Senior Java Developer",
      rating: 4.9,
      experience: "Founder & CTO of Web_Bocket 3+ year of teaching experience",
      skills: [
        "Java", "Spring Boot", "Hibernate", "REST APIs", "SQL", "HTML",
        "CSS", "JavaScript", "Thymeleaf", "Angular", "React", "MySQL",
        "PostgreSQL", "Web Application Development", "Problem Solving",
        "MVC Architecture", "Version Control", "Git", "Cloud Deployment", "Agile"
      ]
    },
    placementStudents: [
      {
        name: "Sambit Ku. Pradhan",
        company: "Web_Bocket",
        salary: "5.0 LPA",
        img: place
      },
      {
        name: "Sumant Ku. Sahu",
        company: "Web_Bocket",
        salary: "5.5 LPA",
        img: place1
      },
      {
        name: "Ashutosh Rout",
        company: "Tech Mahindra",
        salary: "6.2 LPA",
        img: place3
      },
      {
        name: "Bebika Patel",
        company: "Tech Mahindra",
        salary: "5.8 LPA",
        img: place2
      }
    ]
  },
  {
    id: 25,
    details: {
      aos: "flip-right",
      image: Courses4,
      category: "ARTIFICIAL INTELLIGENCE / MACHINE LEARNING",
      name: "AI/ML Beginners to Advanced",
      description: "Start your AI and Machine Learning journey from scratch, progressing to advanced techniques with hands-on projects and real-world applications.",
      lesson: 10,
      students: 60,
      assignment: 6,
      rating: 4.9,
      reviews: 45,
      price: 10000,
      originalPrice: 15000.00,
      enrolled: "10k",
    },
    overview: {
      description: "This AI/ML course is designed to take you from a beginner level to mastering AI and machine learning algorithms. Learn data preprocessing, model building, deep learning, and real-world applications in a comprehensive curriculum.",
      keyPoints1: [
        "Introduction to AI and ML.",
        "Understanding supervised learning.",
        "Exploring unsupervised learning techniques.",
        "Data preprocessing and feature engineering.",
        "Building and evaluating models.",
      ],
      keyPoints2: [
        "Introduction to neural networks.",
        "Deep learning fundamentals and applications.",
        "Implementing natural language processing.",
        "Working with computer vision techniques.",
        "Deploying ML models in production."
      ]
    },
    courseContent: {
      lectures: "120 Lectures",
      courseFaq: [
        {
          id: "faqAIML1",
          question: "What can I expect from the AI/ML Beginners to Advanced course?",
          answer: "This course covers AI/ML fundamentals, including data preprocessing, supervised and unsupervised learning, neural networks, and deep learning techniques. You’ll work on real-world projects to apply machine learning algorithms in various contexts."
        },
        {
          id: "faqAIML2",
          question: "Do I need prior knowledge to enroll in this course?",
          answer: "No prior knowledge is required; this course starts from the basics. However, familiarity with Python programming can be helpful."
        },
        {
          id: "faqAIML3",
          question: "What projects will I work on during the course?",
          answer: "You’ll work on projects like image recognition, sentiment analysis, recommendation systems, and predictive modeling to apply what you learn in real-world scenarios."
        }
      ]
    },
    tutor: {
      pic:"",
      name: "Ramesh ch Mohapatra",
      title: "AI/ML Specialist",
      rating: 4.8,
      experience: "Founder & CTO of Web_Bocket 3+ year of teaching experience",
      skills: [
        "Machine Learning", "Deep Learning", "Python", "TensorFlow",
        "scikit-learn", "Data Preprocessing", "Feature Engineering",
        "Neural Networks", "NLP", "Computer Vision", "Predictive Modeling",
        "Data Analysis", "Model Optimization", "Big Data", "Data Visualization",
        "Keras", "Matplotlib", "Pandas", "NumPy"
      ]
    },
    placementStudents: [
      {
        name: "Sambit Ku. Pradhan",
        company: "Web_Bocket",
        salary: "5.0 LPA",
        img: place
      },
      {
        name: "Sumant Ku. Sahu",
        company: "Web_Bocket",
        salary: "5.5 LPA",
        img: place1
      },
      {
        name: "Ashutosh Rout",
        company: "Tech Mahindra",
        salary: "6.2 LPA",
        img: place3
      },
      {
        name: "Bebika Patel",
        company: "Tech Mahindra",
        salary: "5.8 LPA",
        img: place2
      }
    ]
  },
  {
    id: 5,
    details: {
      aos: "flip-left",
      image: Courses5,
      category: "DATA STRUCTURES & ALGORITHMS",
      name: "DSA Full Course",
      description: "Master Data Structures and Algorithms with hands-on coding exercises and real-world problem-solving strategies. Perfect for acing technical interviews and improving problem-solving skills.",
      lesson: 50,
      students: 75,
      assignment: 8,
      rating: 4.7,
      reviews: 40,
      price: 6500,
      originalPrice: 70000.00,
      enrolled: "10k",
    },
    overview: {
      description: "This comprehensive course in Data Structures and Algorithms (DSA) covers foundational concepts, data structure implementation, algorithm analysis, and real-world problem-solving. Ideal for students and professionals looking to enhance their technical interview skills.",
      keyPoints1: [
        "Introduction to Data Structures.",
        "Understanding Big O notation.",
        "Arrays and Strings fundamentals.",
        "Linked Lists: singly and doubly.",
        "Stacks and Queues implementation.",
      ],
      keyPoints2: [
        "Hash Tables and Hash Maps.",
        "Trees: Binary Trees and BSTs.",
        "Graph theory basics and algorithms.",
        "Sorting algorithms: Quick, Merge, Bubble.",
        "Searching algorithms: Linear and Binary.",
      ]
    },
    courseContent: {
      lectures: "95 Lectures",
      courseFaq: [
        {
          id: "faqDSA1",
          question: "What will I learn in the DSA Full Course?",
          answer: "The course covers data structures such as arrays, linked lists, stacks, queues, trees, and graphs, along with algorithms like sorting, searching, and dynamic programming. You'll gain expertise in problem-solving techniques commonly required in technical interviews."
        },
        {
          id: "faqDSA2",
          question: "Do I need prior programming knowledge for this course?",
          answer: "Basic programming knowledge is recommended, but we start from foundational concepts, so beginners are also welcome."
        },
        {
          id: "faqDSA3",
          question: "How will this course help with technical interviews?",
          answer: "This course provides rigorous practice with data structures and algorithms that are frequently tested in technical interviews. You’ll learn efficient problem-solving techniques, which are essential for interview success."
        }
      ]
    },
    tutor: {
      pic:"",
      name: "Ramesh ch Mohapatra",
      title: "Senior Software Engineer",
      rating: 4.9,
      experience: "Founder & CTO of Web_Bocket 3+ year of teaching experience",
      skills: [
        "Data Structures", "Algorithms", "Java", "Python", "Problem Solving",
        "Complexity Analysis", "Recursion", "Dynamic Programming", "Graph Theory",
        "Tree Structures", "Sorting Algorithms", "Search Algorithms",
        "Competitive Programming", "Software Optimization", "Technical Interview Preparation"
      ]
    },
    placementStudents: [
      {
        name: "Sambit Ku. Pradhan",
        company: "Web_Bocket",
        salary: "5.0 LPA",
        img: place
      },
      {
        name: "Sumant Ku. Sahu",
        company: "Web_Bocket",
        salary: "5.5 LPA",
        img: place1
      },
      {
        name: "Ashutosh Rout",
        company: "Tech Mahindra",
        salary: "6.2 LPA",
        img: place3
      },
      {
        name: "Bebika Patel",
        company: "Tech Mahindra",
        salary: "5.8 LPA",
        img: place3
      }
    ]
  },
  {
    id: 6,
    details: {
      aos: "flip-right",
      image: Courses6,
      category: "DESIGN",
      name: "User Experience Design",
      description: "Learn the essentials of UX design, from user research and wireframing to prototyping and usability testing, to create user-centered designs that solve real problems.",
      lesson: 18,
      students: 50,
      assignment: 4,
      rating: 4.6,
      reviews: 35,
      price: 8500,
      originalPrice: 10000.00,
      enrolled: "10k",
    },
    overview: {
      description: "This User Experience Design course will guide you through the fundamentals of UX design. Learn to craft engaging, user-friendly digital experiences by understanding user needs, testing prototypes, and applying usability principles.",
      keyPoints1: [
        "Introduction to UX design.",
        "Understanding user research methods.",
        "Creating user personas and scenarios.",
        "Conducting usability testing.",
        "Wireframing and prototyping techniques.",
      ],
      keyPoints2: [
        "Visual design principles and elements.",
        "Information architecture and navigation.",
        "Designing for accessibility and inclusivity.",
        "Interaction design and usability heuristics.",
        "Iterative design process and feedback.",
      ]
    },
    courseContent: {
      lectures: "60 Lectures",
      courseFaq: [
        {
          id: "faqUX1",
          question: "What will I learn in the User Experience Design course?",
          answer: "This course covers the fundamentals of UX design, including user research, prototyping, wireframing, and usability testing. You'll learn to create user-centered designs and apply industry-standard techniques to build engaging digital experiences."
        },
        {
          id: "faqUX2",
          question: "Do I need prior experience in design for this course?",
          answer: "No prior design experience is necessary. The course starts with the basics and progressively introduces more advanced UX design techniques."
        },
        {
          id: "faqUX3",
          question: "What tools will I use in this course?",
          answer: "You’ll work with industry-standard tools like Figma, Adobe XD, or Sketch for creating wireframes, prototypes, and design assets."
        }
      ]
    },
    tutor: {
      pic:"",
      name: "Sidharth Srichandan",
      title: "Senior UX Designer",
      rating: 4.7,
      experience: "Founder & CTO of Web_Bocket 3+ year of teaching experience",
      skills: [
        "UX Design", "User Research", "Wireframing", "Prototyping", "Adobe XD",
        "Figma", "Sketch", "Usability Testing", "User Personas", "User Journey Mapping",
        "Responsive Design", "Design Thinking", "Accessibility", "Interaction Design",
        "Portfolio Development", "Design Strategy"
      ]
    },
    placementStudents: [
      {
        name: "Sambit Ku. Pradhan",
        company: "Web_Bocket",
        salary: "5.0 LPA",
        img: place
      },
      {
        name: "Sumant Ku. Sahu",
        company: "Web_Bocket",
        salary: "5.5 LPA",
        img: place1
      },
      {
        name: "Ashutosh Rout",
        company: "Tech Mahindra",
        salary: "6.2 LPA",
        img: place3
      },
      {
        name: "Bebika Patel",
        company: "Tech Mahindra",
        salary: "5.8 LPA",
        img: place2
      }
    ]
  }
]
export default courses