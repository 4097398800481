import React, { useEffect, useState } from "react";
import { FaBars } from "react-icons/fa";
import logo from "../../Assets/logo/wbclogo.svg";
import { useNavigate, useLocation } from "react-router-dom";
import { IoClose } from "react-icons/io5";
import AppointmentModal from "../Home/AppointmentModal";
const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation(); // Get current location
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const getLinkClass = (path) => {
    return location.pathname === path
      ? "text-base font-normal text-[#064585] cursor-pointer tracking-wide"
      : "text-base font-normal hover:text-[#064585] cursor-pointer tracking-wide";
  };
  const [open, setOpen] = useState(false);

  const handleAddOpen = () => {
    setOpen(true);
  };

  const handleModalClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    setTimeout(() => {
      setOpen(true);
    }, 30000)
    setTimeout(() => {
      setOpen(true);
    }, 90000)
    setTimeout(() => {
      setOpen(true);
    }, 150000)
  }, [])
  return (
    <>
      <nav className="hidden md:block bg-[#00112D] text-white px-6 md:px-16 py-2">
        <p className="mx-auto text-center">
          Free Courses 🌟 Sale Ends Soon, Get It Now +91 9861934932, +91
          9776957641
        </p>
      </nav>
      {/* Navbar */}
      <nav className="bg-[#fffefc] text-[#26232A] px-6 md:px-16 py-2 shadow-2xl">
        <div className="container mx-auto flex items-center justify-between">
          {/* 1st Section - Logo */}
          <div>
            <img src={logo} alt="baisaly-logo" className="w-auto h-14" />
          </div>

          {/* Middle Section - Page Links (hidden on small screens) */}
          <div className="hidden md:flex  space-x-8">
            <div onClick={() => navigate("/")} className={getLinkClass("/")}>
              Home
            </div>

            <div
              onClick={() => navigate("/about_us")}
              className={getLinkClass("/about_us")}
            >
              About Us
            </div>
            <div
              onClick={() => navigate("/services")}
              className={getLinkClass("/services")}
            >
              Services
            </div>
            <div
              onClick={() => navigate("/career")}
              className={getLinkClass("/career")}
            >
              Career
            </div>
            <div
              onClick={() => navigate("/contact")}
              className={getLinkClass("/contact")}
            >
              Contact Us
            </div>
          </div>

          {/* 3rd Section - Login Button */}
          <div className="hidden md:block">
            <button className="tracking-wide bg-[#064585] text-white py-2 px-4 rounded" onClick={handleAddOpen}>
              Talk to Us
            </button>
          </div>

          {/* Hamburger Menu for Small Screens */}
          <div className="md:hidden">
            <FaBars
              onClick={toggleSidebar}
              size={24}
              className="cursor-pointer"
            />
          </div>
        </div>
        {open && <AppointmentModal onClose={handleModalClose} />}
      </nav>

      {/* Sidebar for Small Screens */}
      {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-75 z-50">
          <div className="fixed top-0 left-0 w-64 h-full bg-white shadow-md">
            <div className="p-4">
              <button
                className="text-black text-lg font-bold mb-8 float-right"
                onClick={toggleSidebar}
              >
                <IoClose size={25} className="text-[#064585]" />
              </button>

              <ul className="space-y-4 text-lg">
                <li
                  onClick={() => {
                    navigate("/");
                    toggleSidebar();
                  }}
                  className={getLinkClass("/")}
                >
                  Home
                </li>
                <li
                  onClick={() => {
                    navigate("/about_us");
                    toggleSidebar();
                  }}
                  className={getLinkClass("/about_us")}
                >
                  About Us
                </li>
                <li
                  onClick={() => {
                    navigate("/services");
                    toggleSidebar();
                  }}
                  className={getLinkClass("/services")}
                >
                   Services
                </li>
                <li
                  onClick={() => {
                    navigate("/career");
                    toggleSidebar();
                  }}
                  className={getLinkClass("/Career")}
                >
                    Career
                </li>
                <li
                  onClick={() => {
                    navigate("/contact");
                    toggleSidebar();
                  }}
                  className={getLinkClass("/contact")}
                >
                  Contact
                </li>
              </ul>
              <button className="tracking-wide mt-8 bg-[#064585] text-white font-bold py-4 px-6 rounded" onClick={handleAddOpen}>
                Talk to Us
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Navbar;
