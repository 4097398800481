import React from "react";
import Courses1 from "../../Assets/Homeimgs/top-MERN-Stack.webp";
import Courses2 from "../../Assets/Homeimgs/Courses2.png";
import Courses3 from "../../Assets/Homeimgs/Courses3.png";
import Courses4 from "../../Assets/Homeimgs/Courses4.png";
import Courses5 from "../../Assets/Homeimgs/Courses5.png";
import Courses6 from "../../Assets/Homeimgs/Courses6.png";
import star from "../../Assets/Homeimgs/star.svg";
import { useState } from "react";
import webDev from "../../Assets/Services/webDev.png";
import appDev from "../../Assets/Services/AppDev.png";
import dataAnalyst from "../../Assets/Services/DataAnalyst.png";
import FeaturedCoursesbg from "../../Assets/Homeimgs/FeaturedCoursesbackground.png";
import ML from "../../Assets/Services/ML.png";
import DSA from "../../Assets/Services/DSA.png";
import UI from "../../Assets/Services/UI.png";
import FrontendProjects from "../../Assets/Services/Frontend _Project.png";
import fullStack from "../../Assets/Services/Full_stack_Project.png";
import applicationProject from "../../Assets/Services/Application_Projects.png";
import IOT from "../../Assets/Services/IOT.png";
import AI from "../../Assets/Services/AI_Project.png";
import FaqSectionService from "../Services/FaqSectionService";
import AppointmentModal from "../Home/AppointmentModal";

const courses = [
  {
    image: Courses1,
    title: "Full-Stack Web Development In MERN",
    price: 8000,
    originalPrice: 12000.00,
    lessons: "30+",
    assignments: "15+",
    duration: "10k Enrolled",
    rating: 4.0,
    reviews: 15,
  },
  {
    image: Courses2,
    title: "Full-Stack Web Development In Python",
    price: 10000,
    originalPrice: 14000.00,
    lessons: "30+",
    assignments: "15+",
    duration: "10k Enrolled",
    rating: 4.0,
    reviews: 15,
  },
  {
    image: Courses3,
    title: "Full-Stack Java",
    price: 8000,
    originalPrice: 12500.00,
    lessons: "30+",
    assignments: "15+",
    duration: "10k Enrolled",
    rating: 4.0,
    reviews: 15,
  },
  {
    image: Courses4,
    title: "AI/ML Beginners to Advance ",
    price: 10000,
    originalPrice: 15000.00,
    lessons: "30+",
    assignments: "15+",
    duration: "10k Enrolled",
    rating: 4.0,
    reviews: 15,
  },
  {
    image: Courses5,
    title: "DSA Full Course",
    price: 4500,
    originalPrice: 70000.00,
    lessons: "30+",
    assignments: "15+",
    duration: "10k Enrolled",
    rating: 4.0,
    reviews: 15,
  },
  {
    image: Courses6,
    title: "User Experience Design",
    price: 8500,
    originalPrice: 10000.00,
    lessons: "30+",
    assignments: "15+",
    duration: "10k Enrolled",
    rating: 4.0,
    reviews: 15,
  },
];

const internShips = [
  {
    projectName: "Web Development",
    description:
      "Front-end, back-end, databases, and have the ability to work with servers, APIs, and version control systems.",
    rating: 4.0,
    numberOfReviews: 15,
    img: webDev, // Replace with the actual image URL if available
  },
  {
    projectName: "App Development",
    description:
      "Front-end, back-end, databases, and have the ability to work with servers, APIs, and version control systems.",
    rating: 4.0,
    numberOfReviews: 15,
    img: appDev, // Replace with the actual image URL if available
  },
  {
    projectName: "Data Analytics",
    description:
      "Front-end, back-end, databases, and have the ability to work with servers, APIs, and version control systems.",
    rating: 4.0,
    numberOfReviews: 15,
    img: dataAnalyst, // Replace with the actual image URL if available
  },
  {
    projectName: "Machine Learning with Real-life Dataset",
    description:
      "Front-end, back-end, databases, and have the ability to work with servers, APIs, and version control systems.",
    rating: 4.0,
    numberOfReviews: 15,
    img: ML, // Replace with the actual image URL if available
  },
  {
    projectName: "DSA Full Course",
    description:
      "Front-end, back-end, databases, and have the ability to work with servers, APIs, and version control systems.",
    rating: 4.0,
    numberOfReviews: 15,
    img: DSA, // Replace with the actual image URL if available
  },
  {
    projectName: "User Experience Design",
    description:
      "Front-end, back-end, databases, and have the ability to work with servers, APIs, and version control systems.",
    rating: 4.0,
    numberOfReviews: 15,
    img: UI, // Replace with the actual image URL if available
  },
];

const projects = [
  {
    projectName: "Forntend Project",
    projectImage: FrontendProjects, // Replace with actual image URL
  },
  {
    projectName: "Full Stack Project",
    projectImage: fullStack, // Replace with actual image URL
  },
  {
    projectName: "Application Project",
    projectImage: applicationProject, // Replace with actual image URL
  },
  {
    projectName: "IOT Project",
    projectImage: IOT, // Replace with actual image URL
  },
  {
    projectName: "AI Project",
    projectImage: AI, // Replace with actual image URL
  },
];

function Card({ course }) {
  const [open, setOpen] = useState(false);

  const handleAddOpen = () => {
    setOpen(true);
  };

  const handleModalClose = () => {
    setOpen(false);
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-4">
      <img
        src={course.image}
        alt={course.title}
        className="w-full h-48 lg:h-60 object-fill rounded-md"
      />
      <div className="pt-4 space-y-2">
        <h2 className="text-lg font-semibold">{course.title}</h2>
        {/* <div className="flex items-center space-x-2 mt-2">
          <span className="text-red-500 text-xl font-bold">
            ₹{course.price}
          </span>
          <span className="line-through text-gray-500">
            ₹{course.originalPrice}
          </span>
        </div> */}
        <div className="text-gray-600 mt-2 flex justify-between">
          <p>{course.lessons} Lesson</p>
          <p>{course.assignments} Assignments</p>
          <p>{course.duration}</p>
        </div>
        <div className="flex items-center justify-between">
          <div className="flex items-center ">
            <div className="flex items-center text-yellow-500">
              {[...Array(Math.floor(course.rating))].map((_, i) => (
                <img src={star} alt="img" />
              ))}
              {course.rating % 1 !== 0 && (
                <i className="fas fa-star-half-alt"></i>
              )}
            </div>
            <span className="text-gray-600">
              {course.rating} ({course.reviews})
            </span>
          </div>
          <button className="p-3 border rounded-lg shadow-lg" onClick={handleAddOpen} s >
            View Details
          </button>
        </div>
      </div>
      {open && <AppointmentModal onClose={handleModalClose} />}
    </div>
  );
}

function InternShipCards({ internship }) {
  const [open, setOpen] = useState(false);

  const handleAddOpen = () => {
    setOpen(true);
  };

  const handleModalClose = () => {
    setOpen(false);
  };
  return (
    <div className="bg-white rounded-lg p-4  shadow-lg overflow-hidden hover:shadow-xl transition-shadow duration-200">
      <img
        src={internship.img}
        alt={internship.projectName}
        className="w-full h-48 lg:h-60 object-fill rounded-md"
      />
      <div className="mt-4">
        <h3 className="text-xl font-semibold text-gray-800">
          {internship.projectName}
        </h3>
        <p className="text-gray-600 text-lg mt-2 mb-4">
          {internship.description}
        </p>
        <div className="flex items-center justify-between">
          <div className="flex items-center text-yellow-500">
            {/* Display stars based on rating */}
            {Array.from({ length: Math.round(internship.rating) }).map(
              (_, i) => (
                <span key={i}>⭐</span>
              )
            )}
            <span className="text-gray-600 text-xs font-light ml-1">
              {internship.rating} ({internship.numberOfReviews} reviews)
            </span>
          </div>
          <button className=" border tracking-wide border-blue-600 rounded-full py-2 px-3 lg:px-4 lg:py-3  text-sm font-medium hover:bg-blue-900 shadow-lg hover:text-white transition-colors" onClick={handleAddOpen}>
            Enquire Now
          </button>

        </div>
        {open && <AppointmentModal onClose={handleModalClose} />}
      </div>
    </div>
  );
}

function ProjectCards({ project }) {
  let color;
  if (project.projectName === "Forntend Project") {
    color = "#E07DAB";
  } else if (project.projectName === "Full Stack Project") {
    color = "#53BBC8";
  } else if (project.projectName === "Application Project") {
    color = "#939BAE";
  } else if (project.projectName === "IOT Project") {
    color = "#E98E61";
  } else {
    color = "#CD793A";
  }
  const [open, setOpen] = useState(false);

  const handleAddOpen = () => {
    setOpen(true);
  };

  const handleModalClose = () => {
    setOpen(false);
  };
  return (
    <div
      style={{ backgroundColor: color }}
      className={`p-3 lg:p-8 relative flex lg:justify-between justify-center items-center gap-2 lg:gap-0  rounded-lg shadow-lg overflow-hidden hover:shadow-xl transition-shadow duration-200`}
    >
      <div className="lg:w-full w-fit h-full flex flex-col justify-center items-start">
        <h3 className="text-[18px] lg:text-[25.31px] text-white font-bold  ">
          {project.projectName}
        </h3>
        <button className="mt-4  px-2 lg:w-auto lg:px-5 text-white   bg-blue-900 rounded-lg tracking-wide  py-2 text-sm font-medium hover:bg-blue-600 hover:text-white transition-colors" onClick={handleAddOpen}>
          Join With Us
        </button>
      </div>
      <img
        src={project.projectImage}
        alt={project.projectName}
        className="  lg:w-[12rem] w-[9rem]"
      />
         {open && <AppointmentModal onClose={handleModalClose} />}
    </div>
  );
}

const Content_Section = () => {
  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    { label: "Top Notch Courses", icon: "💻" },
    { label: "Part / Full Time Internships", icon: "🛠️" },
    { label: "Live Projects", icon: "🚀" },
  ];

  const CourseFAQs = [
    {
      id: "faqA",
      question:
        "What will I learn in the Full-Stack Web Development in MERN course?",
      answer:
        "The MERN stack course covers MongoDB for databases, Express.js and Node.js for backend development, and React for frontend development. You'll learn to build full-stack applications, including RESTful APIs, user authentication, and deployment.",
    },
    {
      id: "faq1",
      question:
        "What are the prerequisites for Full-Stack Web Development in Python?",
      answer:
        "Basic knowledge of Python programming is recommended. The course covers web frameworks like Django or Flask, along with frontend skills, database management, and deployment strategies.",
    },
    {
      id: "faq2",
      question: "What does the Full-Stack Java course entail?",
      answer:
        "This course teaches full-stack development with Java, including backend frameworks like Spring Boot, frontend technologies such as JavaScript, and database management. You’ll gain skills in building robust, scalable web applications.",
    },
    {
      id: "faq3",
      question:
        "What can I expect from the AI/ML Beginners to Advanced course?",
      answer:
        "This course covers AI/ML fundamentals, including data preprocessing, supervised and unsupervised learning, neural networks, and deep learning techniques. You’ll work on real-world projects to apply machine learning algorithms in various contexts.",
    },
    {
      id: "faq4",
      question: "What topics are covered in the DSA Full Course?",
      answer:
        "The DSA course covers essential data structures like arrays, linked lists, stacks, and queues, along with algorithms such as sorting, searching, and dynamic programming. The course aims to strengthen your problem-solving skills for coding interviews.",
    },
    {
      id: "faq5",
      question:
        "What skills will I gain from the User Experience (UX) Design course?",
      answer:
        "In the UX Design course, you’ll learn principles of user-centered design, usability testing, wireframing, prototyping, and tools like Figma or Sketch. You’ll gain practical skills to create intuitive, user-friendly interfaces.",
    },
  ];

  const InternShipfaqs = [
    {
      id: "faqA",
      question: "Who is eligible to apply for the internship?",
      answer:
        "To apply, candidates should typically be enrolled in or have completed one of our courses, though requirements may vary based on the internship role. We recommend checking specific eligibility criteria for each internship posting.",
    },
    {
      id: "faq1",
      question: "What will I work on as an intern?",
      answer:
        "Interns typically work on real-world projects related to their area of study. Specific tasks depend on the department and project needs but often include research, development, testing, or content creation.",
    },
    {
      id: "faq2",
      question: "How will I be evaluated during my internship?",
      answer:
        "Evaluations are conducted by your assigned mentor or supervisor based on project contributions, punctuality, skill application, and adaptability.",
    },
    {
      id: "faq3",
      question: "What is the duration of the internship program?",
      answer:
        "The duration of the internship can vary based on the program and project requirements. Typically, internships last from 3 to 6 months.",
    },
    {
      id: "faq4",
      question: "Who is eligible to apply for the internship?",
      answer:
        "To apply, candidates should typically be enrolled in or have completed one of our courses, though requirements may vary based on the internship role. We recommend checking specific eligibility criteria for each internship posting.",
    },
    {
      id: "faq5",
      question: "Can I convert this internship into a full-time role?",
      answer:
        "Yes, high-performing interns may be offered a full-time position based on available roles and departmental needs at the end of their internship.",
    },
  ];

  const ProjectFAQs = [
    {
      id: "faqA",
      question: "Can I work on multiple projects at the same time?",
      answer:
        "While it is possible to work on multiple projects, it depends on your capacity and the complexity of each project. It's often beneficial to focus on one project at a time to ensure quality and effective learning.",
    },
    {
      id: "faq1",
      question: "What skills do I need to work on a Frontend Project?",
      answer:
        "For frontend projects, proficiency in HTML, CSS, and JavaScript is essential. Knowledge of frameworks like React or Vue.js, and familiarity with responsive design, is also beneficial.",
    },
    {
      id: "faq2",
      question: "What does a Full Stack Project involve?",
      answer:
        "A full stack project involves working on both the frontend and backend. You’ll work with frontend frameworks like React, backend technologies like Node.js or Django, and databases such as MongoDB or PostgreSQL to create a complete application.",
    },
    {
      id: "faq3",
      question: "What kind of tasks are involved in an Application Project?",
      answer:
        "Application projects generally involve creating software applications for mobile or desktop platforms. Common tasks include UI/UX design, API integration, and performance optimization, along with testing and debugging.",
    },
    {
      id: "faq4",
      question: "What skills are required for an IoT Project?",
      answer:
        "For IoT projects, knowledge of embedded systems, sensor integration, and data communication protocols like MQTT and HTTP is essential. Skills in programming languages like C, C++, or Python, as well as familiarity with microcontrollers like Arduino or Raspberry Pi, are also beneficial.",
    },
    {
      id: "faq5",
      question: "What does an AI Project entail?",
      answer:
        "AI projects typically involve developing algorithms and models to analyze data and make predictions. Knowledge of machine learning frameworks like TensorFlow or PyTorch, along with skills in Python and understanding of data preprocessing, model training, and evaluation, are essential.",
    },
  ];

  let faqs;
  if (activeTab === 0) {
    faqs = [...CourseFAQs];
  } else if (activeTab === 1) {
    faqs = [...InternShipfaqs];
  } else {
    faqs = [...ProjectFAQs];
  }
  return (
    <div className="lg:py-16 py-6 bg-[#FDF2F8]">
      {/* Tab section  */}
      <div className="w-full py-1 px-2 md:px-8 lg:px-16">
        <div className="flex justify-around md:justify-around space-x-4 sm:space-x-6 md:space-x-8 lg:space-x-12 pb-2">
          {tabs.map((tab, index) => (
            <button
              key={index}
              onClick={() => setActiveTab(index)}
              className={`flex flex-col items-center text-gray-600 transition duration-200 ${activeTab === index ? "text-blue-600" : ""
                }`}
            >
              {/* Icon */}
              <div
                className={`text-2xl sm:text-3xl md:text-4xl mb-1 ${activeTab === index ? "text-blue-600" : "text-gray-400"
                  }`}
              >
                {tab.icon}
              </div>
              {/* Label */}
              <span className="font-medium tracking-wide text-xs sm:text-sm md:text-base lg:text-lg xl:text-xl">
                {tab.label}
              </span>
              {/* Active Tab Indicator */}
              {activeTab === index && (
                <div className="w-full h-0.5 bg-blue-600 mt-2"></div>
              )}
            </button>
          ))}
        </div>
      </div>

      <div className="grid  md:grid-cols-2 lg:grid-cols-3 grid-cols-1 md:mx-16 mx-6 gap-10 mt-10">
        {activeTab === 0 &&
          courses.map((course, index) => <Card key={index} course={course} />)}

        {activeTab === 1 &&
          internShips.map((internship, index) => (
            <InternShipCards key={index} internship={internship} />
          ))}

        {activeTab === 2 &&
          projects.map((project, index) => (
            <ProjectCards key={index} project={project} />
          ))}
      </div>

      {/* FAQ SECTION */}
      <FaqSectionService faqs={faqs} />
    </div>
  );
};

export default Content_Section;
