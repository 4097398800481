import React from "react";
import Banner from "../../Components/ReusableComponents/Banner";
import TrustedBy from "../../Components/Home/TrustedBy";
import RecruitersPartners from "../../Components/About/RecruitersPartners";
import FooterBanner from "../../Components/ReusableComponents/FooterBanner";
import GetFromUs from "../../Components/About/GetFromUs";
import StrongFoundations from "../../Components/About/StrongFoundations";
import OurVision from "../../Components/About/OurVision";
import QuarterlyReview from '../../Components/About/QuarterlyReview'
const About = () => {
  return (
    <div className="overflow-x-hidden">
      <Banner page="About Us" path="About Us" />
      <StrongFoundations/>
      <OurVision/>
      <QuarterlyReview/>
      <GetFromUs/>
      <TrustedBy/>
      <RecruitersPartners/>
      <FooterBanner/>
    </div>
  );
};

export default About;
