import React from 'react';
import img1 from '../../Assets/Homeimgs/Masterimg.png';
import img2 from '../../Assets/Homeimgs/Motivated.svg';
import img3 from '../../Assets/Homeimgs/Keepup.svg';
import img4 from '../../Assets/Homeimgs/GetCertified.svg';
import img5 from '../../Assets/Homeimgs/BuildSkills.svg';

const MasterTheSkills = () => {
    return (
        <div className="flex flex-col md:flex-row md:mx-16 mx-6">
            <div className="md:w-3/5 flex flex-col justify-center">
                <p className="text-[#5989C8] italic font-semibold text-lg md:text-xl" data-aos="fade-right">What's New</p>
                <h1 className="text-2xl md:text-4xl font-bold text-black mt-2" data-aos="fade-left">Master the Skills to Drive your Career</h1>
                <p className="text-gray-600 mt-4" data-aos="fade-up-right">
                    Get certified, master modern tech skills, and level up your career — whether you’re starting
                    out or a seasoned pro. 95% of eLearning learners report our hands-on content directly
                    helped their careers.
                </p>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 md:gap-6 mt-6 md:mt-8">
                    <div className="flex items-center p-4 md:p-6 rounded-lg shadow-md border" data-aos="flip-left">
                        <img src={img2} alt="Icon" className="w-12 h-12 md:w-16 md:h-16" />
                        <h3 className="ml-4 text-md md:text-lg font-semibold text-gray-900">Stay Motivated with Engaging Instructors</h3>
                    </div>
                    <div className="flex items-center p-4 md:p-6 rounded-lg shadow-md border"  data-aos="flip-right">
                        <img src={img3} alt="Icon" className="w-12 h-12 md:w-16 md:h-16" />
                        <h3 className="ml-4 text-md md:text-lg font-semibold text-gray-900">Keep up with the Latest in Cloud</h3>
                    </div>
                    <div className="flex items-center p-4 md:p-6 rounded-lg shadow-md border" data-aos="flip-up">
                        <img src={img4} alt="Icon" className="w-12 h-12 md:w-16 md:h-16" />
                        <h3 className="ml-4 text-md md:text-lg font-semibold text-gray-900">Get Certified with 100+ Certification Courses</h3>
                    </div>
                    <div className="flex items-center p-4 md:p-6 rounded-lg shadow-md border" data-aos="flip-down">
                        <img src={img5} alt="Icon" className="w-12 h-12 md:w-16 md:h-16" />
                        <h3 className="ml-4 text-md md:text-lg font-semibold text-gray-900">Build Skills your Way, from Labs to Courses</h3>
                    </div>
                </div>
            </div>
            <div className="md:w-2/5 mt-8 md:mt-0 flex justify-center">
                <img src={img1} alt="A person" className="w-full h-auto" data-aos="zoom-in-down"/>
            </div>
        </div>
    );
}

export default MasterTheSkills;
