import React, { useState } from "react";
import employeeLogo from "../../Assets/logo/wbclogo.svg";
import JobApplicationModal from "./JobApplicationModal"; // Import the modal component

const jobData = [
  {
    title: "Marketing Executive",
    type: "Full-TIME",
    salary: "12,000 - 15,000",
    company: "Web_Bocket Classes",
    location: "Bhubaneswar",
    logo: employeeLogo,
  },
  {
    title: "Java Trainer",
    type: "Full-TIME",
    salary: "13,000 - 25,000",
    company: "Web_Bocket Classes",
    location: "Bhubaneswar",
    logo: employeeLogo,
  },
  {
    title: "MERN Stack Trainer",
    type: "Full-TIME",
    salary: "13,000 - 18,000",
    company: "Web_Bocket Classes",
    location: "Bhubaneswar",
    logo: employeeLogo,
  },
  {
    title: "Student Counselor",
    type: "Full-TIME",
    salary: "12,000 - 17,000",
    company: "Web_Bocket Classes",
    location: "Bhubaneswar",
    logo: employeeLogo,
  },
  {
    title: "Senior Marketing Executive",
    type: "Full-TIME",
    salary: "18,000 - 28,000",
    company: "Web_Bocket Classes",
    location: "Bhubaneswar",
    logo: employeeLogo,
  },
  {
    title: "Field Officer",
    type: "Full-TIME",
    salary: "10,000 - 15,000k",
    company: "Web_Bocket Classes",
    location: "Bhubaneswar",
    logo: employeeLogo,
  },
];

const JobOpennings = () => {
  const [selectedJob, setSelectedJob] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = (job) => {
    setSelectedJob(job);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedJob(null);
    setIsModalOpen(false);
  };

  return (
    <div className="bg-pink-50 px-6 md:px-16 py-12 md:py-16">
      <div className="text-center pb-10">
        <h2 className="text-blue-500 text-[16px] font-medium italic mb-2 ">
          Our Jobs
        </h2>
        <h1 className="text-[24px] lg:text-[40px] font-semibold text-gray-900">
          Current Job Openings
        </h1>
      </div>
      <div className="w-full mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 lg:px-4">
        {jobData.map((job, index) => (
          <div key={index} className="bg-white p-6 rounded-lg shadow-md">
            <h3 className="text-lg font-semibold text-gray-900">{job.title}</h3>
            <span className="inline-block bg-green-100 text-green-700 text-xs font-medium mt-2 px-2 py-1 rounded">
              {job.type}
            </span>
            <p className="text-gray-600 mt-2">Salary: {job.salary}</p>
            <div className="flex items-center mt-4">
              <img
                src={job.logo}
                alt={`${job.company} logo`}
                className="w-10 h-10 rounded-full"
              />
              <div className="ml-3">
                <p className="text-gray-900 font-medium">{job.company}</p>
                <p className="text-gray-600 text-sm">
                  <i className="fas fa-map-marker-alt"></i> {job.location}
                </p>
              </div>
            </div>
            <button
              className="float-right mt-4 bg-[#034E9B] font-medium text-[16px] text-white px-7 py-2 rounded-lg"
              onClick={() => openModal(job)} // Open modal on click
            >
              Apply
            </button>
          </div>
        ))}
      </div>

      {isModalOpen && (
        <JobApplicationModal job={selectedJob} onClose={closeModal} />
      )}
    </div>
  );
};

export default JobOpennings;
