import React from 'react'
import Overview from '../../Components/DetailsPage.jsx/Overview'
import SimpleBanner from '../Common/SimpleBanner'
import AboutUs_banner from '../../Assets/detailspage/bg.png'
import FooterBanner from '../../Components/ReusableComponents/FooterBanner'
import { useParams } from 'react-router-dom'
import courses from '../../Components/Home/data'
const DetailsPage = () => {
  const { id } = useParams();
  
  const courseData = courses.find((each, index) => {
    return each.id === Number(id)
  })

  return (
    <div>
      <SimpleBanner
        image={AboutUs_banner}
        category={courseData.details.category}
        name={courseData.details.name}
        description={courseData.details.description}
        lesson={courseData.details.lesson}
        students={courseData.details.students}
      />
      <div className='bg-[#fff8f8]'>
        <Overview courseData={courseData}/>
        <FooterBanner />
      </div>
    </div>
  )
}

export default DetailsPage